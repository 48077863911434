
import { LmvMatrix4 as Matrix4 } from '../../wgs/scene/LmvMatrix4';
import { LmvBox3 as Box3 } from '../../wgs/scene/LmvBox3';
import { logger } from "../../logger/Logger";
import { TextureLoader } from "./TextureLoader";
import { BVH } from "../../wgs/scene/BVHBuilder";
import { MeshFlags } from '../../wgs/scene/MeshFlags';
import { PropDbLoader } from "./PropDbLoader";
import { createWorker } from "./WorkerCreator";
import { initLoadContext } from "../net/endpoints";
import { pathToURL, ViewingService } from "../net/Xhr";
import {OtgPackage, FLUENT_URN_PREFIX, DS_OTG_CDN_PREFIX} from "../lmvtk/otg/Otg";
import { FileLoaderManager } from "../../application/FileLoaderManager";
import { Model } from "../../application/Model";
import { BubbleNode } from "../../application/bubble";
import * as et from "../../application/EventTypes";
import { LocalStorage } from '../../application/LocalStorage.js';
import { ProgressState } from '../../application/ProgressState';
import { isMobileDevice } from "../../compat";
import { MESH_RECEIVE_EVENT, MESH_FAILED_EVENT, MATERIAL_RECEIVE_EVENT, MATERIAL_FAILED_EVENT } from "./OtgResourceCache";
import { MODEL_CONSOLIDATION_ENABLED } from '../../application/PrivateEventTypes';
import { binToPackedString, bin16ToPackedString, packedToBin, digest } from '../lmvtk/otg/HashStrings';
import { USE_HLOD, BVH_CACHING, CONSOLIDATION_MEMORY_LIMIT, USE_OUT_OF_CORE_TILE_MANAGER, USE_WEBGPU, USE_MULTI_MATERIAL_RENDER_CALLS } from "../../wgs/globals";
import {OtgFragInfo} from "../lmvtk/otg/OtgFragInfo";
//import { createWireframe } from "../../wgs/scene/DeriveTopology;
import { SelectiveLoadingController } from '../selective/SelectiveLoadingController';
import { getUInt8Buffer } from "../../wgs/scene/RenderModel";
import { OutOfCoreTileManager } from '../../wgs/scene/out-of-core-tile-manager/OutOfCoreTileManager.js';

/** @import { Viewer3dImpl } from "../../application/Viewer3DImpl"} */

var WORKER_LOAD_OTG_BVH = "LOAD_OTG_BVH";
const av = Autodesk.Viewing;
const avp = Autodesk.Viewing.Private;

// Maximum number of geometries to add to the priority queue when loading from cache
const MAX_PRIORITY_QUEUE_REQUEST_WHEN_LOADING_FROM_CACHE = 1000;
/**
 * @constructor
 * @param {Viewer3dImpl} parent
 */
export function OtgLoader(parent) {
    this.viewer3DImpl = parent;
    this.loading = false;
    this.tmpMatrix = new Matrix4();
    this.tmpBox = new Box3();

    this.logger = logger;
    this.loadTime = 0;

    this.pendingMaterials = new Map();

    this.pendingMeshes = new Array();
    this.pendingMeshesBvh = new Array();

    this.pendingRequests = 0;

    this.operationsDone = 0;
    this.viewpointMatHashes = new Set();

    this.notifiesFirstPixel = true;

    this._selectiveLoadingController = new SelectiveLoadingController(OtgLoader.prototype.evaluate.bind(this));

    this._lineageUrn = null;

    this._notLoadedGeomHashes = {};

    this.loadFromCache = false;
    this.consolidationMapLoadedFromCache = false;
    this._extraMaterialHashesLoadedFromCache = false;
}


OtgLoader.prototype.dtor = function () {
    // Cancel all potential process on loading a file.

    // 2. load model root (aka. svf) can be cancelled.
    //
    if (this.bvhWorker) {
        this.bvhWorker.terminate();
        this.bvhWorker = null;
        logger.debug("SVF loader dtor: on svf worker.");
    }


    if (this.svf) {

        if (!this.svf.loadDone)
            console.log("stopping load before it was complete");

        this.svf.abort();

        if (this.svf.propDbLoader) {
            this.svf.propDbLoader.dtor();
            this.svf.propDbLoader = null;
        }
    }


    // 5. Cancel all running requests in shared geometry worker
    //
    if (this.viewer3DImpl.geomCache() && this.model) {
        if (this.loading)
            this.viewer3DImpl.geomCache().cancelRequests(this.svf.geomMetadata.hashToIndex.keys());

        this.pendingRequests = 0;
        this.removeMeshReceiveListener();
    }

    if (this.consolidationEventHandler) {
        this.viewer3DImpl.api.removeEventListener(MODEL_CONSOLIDATION_ENABLED, this.consolidationEventHandler);
        this.consolidationEventHandler = null;
    }

    // and clear metadata.
    this.viewer3DImpl = null;
    this.model = null;
    this.svf = null;
    this.logger = null;
    this.tmpMatrix = null;

    this.loading = false;
    this.loadTime = 0;
};

OtgLoader.prototype.isValid = function() {
    return this.viewer3DImpl != null;
};

OtgLoader.prototype.removeMeshReceiveListener = function() {
    if (!this.meshReceivedListener) {
        return;
    }
    this.viewer3DImpl.geomCache().loaderRemoved(this);
    this.viewer3DImpl.geomCache().removeEventListener(MESH_RECEIVE_EVENT, this.meshReceivedListener);
    this.viewer3DImpl.geomCache().removeEventListener(MESH_FAILED_EVENT,  this.meshFailedListener);
    this.viewer3DImpl.geomCache().removeEventListener(MATERIAL_RECEIVE_EVENT, this.materialReceiveListener);
    this.viewer3DImpl.geomCache().removeEventListener(MATERIAL_FAILED_EVENT,  this.materialReceiveListener);
    this.meshReceivedListener = null;
    this.meshFailedListener = null;
};

function getBasePath(path) {
    var basePath = "";
    var lastSlash = path.lastIndexOf("/");
    if (lastSlash != -1)
        basePath = path.substr(0, lastSlash+1);
    return basePath;
}

function getQueryParams(options) {
    return options.acmSessionId ? "acmsession=" + options.acmSessionId : "";
}

function createLoadContext(options, basePath, queryParams) {
    var loadContext = {
        basePath: basePath,
        objectIds : options.ids,
        globalOffset : options.globalOffset,
        fragmentTransformsDouble: options.fragmentTransformsDouble,
        placementTransform : options.placementTransform,
        applyRefPoint: options.applyRefPoint,
        queryParams : queryParams,
        bvhOptions : options.bvhOptions || {isWeakDevice : isMobileDevice()},
        applyScaling: options.applyScaling,
        applyPlacementInModelUnits: options.applyPlacementInModelUnits,
        loadInstanceTree: options.loadInstanceTree,
        // avoidNwcRotation: options.avoidNwcRotation,
    };

    return initLoadContext(loadContext);
}

OtgLoader.prototype.loadFile = function(path, options, onDone, onWorkerStart) {
    if (!this.viewer3DImpl) {
        logger.log("SVF loader was already destructed. So no longer usable.");
        return false;
    }

    this.viewer3DImpl._signalNoMeshes();

    if (this.loading) {
        logger.log("Loading of SVF already in progress. Ignoring new request.");
        return false;
    }

    // Mark it as loading now.
    this.loading = true;

    //For OTG server, the URN of the manifest is used as part of the ACM session token
    if (options.acmSessionId) {
        //TODO: initWorker should be updated to also send the acmsession when authorizing the web worker,
        //in a followup change.
        this.svfUrn = options.acmSessionId.split(",")[0];
    } else {
        //If the URN is not supplied, we can derive it from the storage path,
        //but that will only work for URNs that are not shallow copies.
        console.warn("DEPRECATED: Automatic derivation of URN will be removed in a future release. Please set the acmSessionId parameter when loading OTG data.");

        var idx = path.indexOf(FLUENT_URN_PREFIX);
        if (idx === -1) {
            idx = path.indexOf(DS_OTG_CDN_PREFIX);
        }

        if (idx !== -1) {

            //This will work for WIP URNs but probably not OSS ones, where
            //the URN is an encoding of the OSS file name or something equally arbitrary
            var parts = path.split("/");
            var seed = parts[1];
            var version = parts[2];
            var urn = seed + "?version=" + version;
            var encoded = av.toUrlSafeBase64(urn);

            this.svfUrn = encoded;
        }
    }

    this.sharedDbPath = options.sharedPropertyDbPath;

    this.currentLoadPath = path;

    this.basePath = getBasePath(path);
    this.acmSessionId = options.acmSessionId;

    this.options = options;
    this.queryParams = getQueryParams(options);

    this.loadContext = createLoadContext(options, this.basePath, this.queryParams);

    // The request failure parameters received by onFailureCallback (e.g. httpStatusCode) cannot just be forwarded to onDone().
    // Instead, we have to pass them through ViewingService.defaultFailureCallback, which converts them to error parameters
    // and calls loadContext.raiseError with them.
    this.loadContext.raiseError = function(code, msg, args) {
        var error = { "code": code, "msg": msg, "args": args };
        onDone && onDone(error);
    };
    this.loadContext.onFailureCallback = ViewingService.defaultFailureCallback.bind(this.loadContext);

    // For fine grained uniform updates, we need the FragmentList to be able to map dbIds to fragIds (see FragmentList.setThemingColor)
    if (this.viewer3DImpl.isWebGPU() || USE_MULTI_MATERIAL_RENDER_CALLS) {
        this.loadContext.buildDbId2FragIdMap = true;
    }

    this._selectiveLoadingController.prepare(options);

    this.loadModelRoot(this.loadContext, onDone);

    //We don't use a worker for OTG root load, so we call this back immediately
    //We will use the worker for heavy tasks like BVH compute after we get the model root file.
    onWorkerStart && onWorkerStart();

    return true;
};

OtgLoader.prototype._requestRepaint = function() {
    if (!this.viewer3DImpl?.modelVisible(this.model.id)) {
        return;
    }
    this.viewer3DImpl.api.dispatchEvent({ type: av.LOADER_REPAINT_REQUEST_EVENT,
        loader: this, model: this.model });
};

OtgLoader.prototype.loadModelRoot = async function(loadContext, onDone) {
    this.t0 = new Date().getTime();
    this.firstPixelTimestamp = null;
    var scope = this;

    var svf = this.svf = new OtgPackage();

    svf.basePath = loadContext.basePath;

    //Those events happen on the main thread, unlike SVF loading where
    //everything happens in the svfWorker
    loadContext.onLoaderEvent = function(whatIsDone, data) {

        if (!scope.svf) {
            console.error("load callback called after load was aborted");
            return;
        }

        if (whatIsDone === "otg_root") {

            scope.onModelRootLoadDone(svf);

            if (onDone)
                onDone(null, scope.model);

            // init shared cache on first use
            const geomCache = scope.viewer3DImpl.geomCache();

            geomCache.modelAdded(scope.options.acmSessionId, scope._lineageUrn);

            scope.meshReceivedListener = (event) => scope.onMeshReceived(event.geom);
            scope.meshFailedListener = (event) => scope.onMeshError(event.hash);
            geomCache.addEventListener(MESH_RECEIVE_EVENT, scope.meshReceivedListener);
            geomCache.addEventListener(MESH_FAILED_EVENT, scope.meshFailedListener);

            scope.materialReceiveListener = (event) => scope.onMaterialLoaded(event.material, event.hash);
            geomCache.addEventListener(MATERIAL_RECEIVE_EVENT, scope.materialReceiveListener);
            geomCache.addEventListener(MATERIAL_FAILED_EVENT, scope.materialReceiveListener);

            scope.svf.loadDone = false;

        } else if (whatIsDone === "fragment") {

            if (!scope.options.skipMeshLoad) {
                const fragmentID = data;
                if (scope._selectiveLoadingController.isActive) {
                    scope._selectiveLoadingController.onFragmentReady(fragmentID);
                } else {
                    scope.tryToActivateFragment(fragmentID, "fragment");
                }
            }

            // Optional: Track fragment load progress separately
            if (scope.options.onFragmentListLoadProgress) {
                scope.trackFragmentListLoadProgress();
            }

        } else if (whatIsDone === "all_fragments") {

            //For 3D models, we can start loading the property database as soon
            //as we know the fragment list which contains the fragId->dbId map.
            if (!scope.options.skipPropertyDb) {
                scope.loadPropertyDb();
                scope._selectiveLoadingController.onPropertiesReady();
            }

            // If this flag is false, some data is not ready yet. E.g. fragments.fragId2DbId is initially
            // filled with zeros and is only be usable after root looad. Note that fragDataLoaded = true
            // does NOT mean yet that geometry and materials are all loaded.
            scope.fragmentDataLoaded = true;

            scope._selectiveLoadingController.onFragmentListReady();

            scope.viewer3DImpl.api.fireEvent({type:et.MODEL_ROOT_LOADED_EVENT, svf:svf, model:scope.model});

            if (scope.options.skipMeshLoad || !scope.svf.fragments.length) {
                scope.onGeomLoadDone();
            } else {
                scope.onOperationComplete();
            }

            if (scope.loadFromCache) {
                // In this case, boxes come from the fragment list. They're done when the fraglist is done.
                scope.model.boxesLoadingDone();
            }
            if (USE_OUT_OF_CORE_TILE_MANAGER) {
                scope.tryEarlyConsolidation();
            }

        } else if (whatIsDone === "viewpointData") {
            // Load extra materials if viewpoints enabled as some materials are needed for override Sets
            if (scope.svf.needExtraMaterials) {
                scope.tryToLoadExtraMaterials();
            } else {
                scope.onOperationComplete();
            }

            if (scope.svf.viewpointTreeRoot && scope.options.addViews) {
                scope.options.addViews(scope.svf.viewpointTreeRoot, scope.model);
            }
        }
    };

    loadContext.onOperationComplete = function() {
        scope.onOperationComplete();
    };

    const geomCache = this.viewer3DImpl.geomCache();

    geomCache.loaderAdded(scope);

    const metadataPromise = this.svf.loadMetadata(this.loadContext, pathToURL(this.currentLoadPath));
    this.svf.initializeDbIdFilter(this.loadContext.objectIds);

    if (BVH_CACHING && !this.options?.disableCache && !this._selectiveLoadingController.isActive) {
        await this.tryLoadFromCache(loadContext, metadataPromise);
        if (scope.svf === null) { // model may have been closed
            return;
        }
    }

    if (this.loadFromCache) {
        return;
    }

    // load from server
    this.svf.loadData(this.loadContext);
    if (USE_OUT_OF_CORE_TILE_MANAGER) {
        // We inform the geom cache, that we are currently downloading the fragment list.
        // While this is in process, we will throttle the download of the geometry data,
        // because the fragment list is more important to be able to compute the BVH
        // as soon as possible and get better geometry prioritization afterwards.
        geomCache.throttleDownload();
    }

    await metadataPromise;
    if (scope.svf === null) { // model may have been closed
        return;
    }

    scope.makeBVHInWorker();
};

OtgLoader.prototype.tryLoadFromCache = async function(loadContext, metadataPromise) {
    this.hlodCacheIdentifier = this.getHLODCacheIdentifier();
    const version = 3; // TODO on next version, remove unused header field from getCachedBVH/cacheBVH
    this.hlodCacheBucket = "hlod_cache_v" + version + "_" + Autodesk.Viewing.fromUrlSafeBase64(this.options.bubbleNode.getRootNode().urn());

    // this has to be done beforehand since crypto.subtle.digest would stall frag list chunk writing and therefore mess up control flow if data is updated while writing
    this.fragListHashIdentifier = new Uint8Array(await digest("SHA-1", `${this.hlodCacheIdentifier}_frag_list`));

    // cancel loading, if cache is incomplete. we can improve this once frag list chunks are available to load from the server
    if (!this.getHLODCachedFlag()) {
        this.loadFromCache = false;
        return;
    }

    const bvh = await this.getCachedBVH();
    if (!bvh) {
        // reset flag in case the cache was emptied
        this.setHLODCachedFlag(false);
        this.loadFromCache = false;
        return;
    }

    // have to wait for that, otherwise there's no this.model if the cache was faster than the root
    await metadataPromise;

    this.setBVH(bvh);
    this.onOperationComplete();

    // Precise boxes are contained in the cached fragment list
    this.model.getFragmentList().fragments.dontComputeBoxes = true;

    this.svf.initializeGeometryHashList(/*geometryHashesByteStride*/ 20, /*geometryHashesVersion*/ 1);
    this.svf.initializeMaterialHashList(/*materialHashesByteStride*/ 20, /*materialHashesVersion*/ 1);

    this.loadFromCache = true;

    this.getCachedFragList(loadContext);
};

OtgLoader.prototype.makeBVHInWorker = function() {

    var scope = this;

    //We can kick off the request for the fragments-extra file, needed
    //for the BVH build as soon as we have the metadata (i.e. placement transform)
    //Do this on the worker thread, because the BVH build can take a while.
    var workerContext = Object.assign({}, scope.loadContext);
    workerContext.operation = WORKER_LOAD_OTG_BVH;
    workerContext.raiseError = null;
    workerContext.onFailureCallback = null;
    workerContext.onLoaderEvent = null;
    workerContext.onOperationComplete = null;
    workerContext.fragments_extra = pathToURL(scope.basePath) + scope.svf.manifest.assets.fragments_extra;
    workerContext.placementTransform = scope.svf.placementTransform;
    workerContext.placementWithOffset = scope.svf.placementWithOffset;
    workerContext.fragmentTransformsOffset = scope.svf.metadata.fragmentTransformsOffset;
    workerContext.globalOffset = scope.svf.globalOffset;

    if (USE_HLOD) {
        // We need sorted fragments for render order prioritization. That should already be the case for OTG models.
        // In practice that is not always the case, so we enforce sorting here.
        workerContext.wantSort = true;
    }

    if (workerContext.fragments_extra) {
        if (USE_HLOD && USE_OUT_OF_CORE_TILE_MANAGER) {
            this.svf.loadAsyncResource(scope.loadContext, workerContext.fragments_extra, "", function(data) {
                if (scope.svf === null) { // model may have been closed
                    return;
                }
                scope.svf.extraFragInfo = new OtgFragInfo(data, workerContext);

                // Operation complete will be called within tryEarlyConsolidation, once FragmentList, FragExtras and BVH
                // are ready.
                scope.tryEarlyConsolidation();
            });
        } else {
            scope.bvhWorker = createWorker('OtgBvhWorker');

            var onOtgWorkerEvent = function(e) {
                if (scope.svf === null) { // model may have been closed
                    return;
                }

                console.log("Received BVH from worker");

                var bvh = new BVH(e.data.bvh.nodes, e.data.bvh.useLeanNodes, e.data.bvh.primitives, scope.options.bvhOptions);

                // boxes should be set first so that selectiveLoadingController in setBVH can use them
                scope.model.setFragmentBoundingBoxes(e.data.boxes, e.data.boxStride);
                scope.setBVH(bvh);

                scope.bvhWorker.terminate();
                scope.bvhWorker = null;

                scope.onOperationComplete();
            };

            scope.bvhWorker.addEventListener('message', onOtgWorkerEvent);

            scope.bvhWorker.doOperation(workerContext);
        }
    } else {
        // If the model does not reference a fragment_extra file, the worker would not do anything.
        // This is okay for empty models. For this case, just skip the BVH phase to avoid the load progress from hanging.
        scope.onOperationComplete();
    }
};

OtgLoader.prototype.setBVH = function(bvh) {
    this.svf.bvh = bvh;
    this.model.setBVH(bvh);

    this._selectiveLoadingController.onBoundingVolumeHierarchyReady();
    this._requestRepaint();
};

OtgLoader.prototype.getHLODCacheIdentifier = function () {
    const params = {
        bvhOptions: this.options.bvhOptions,
        placementWithOffset: this.options.placementWithOffset,
        placementTransform: this.options.placementTransform?.elements,
        globalOffset: this.options.globalOffset,
        byteLimit: this.options.consolidationMemoryLimit || CONSOLIDATION_MEMORY_LIMIT,
        viewGuid: this.options.bubbleNode.guid(),
    };

    // Add WebGPU flag. For WebGL, we don't add false to avoid unnecessary recomputation of all existing caches.
    if(USE_WEBGPU) {
        params.webgpu = true;
    }
    return JSON.stringify(params);

};

OtgLoader.prototype.getCachedBVH = async function() {

    const geomCache = this.viewer3DImpl.geomCache();

    const data = (await geomCache.opfsGet(this.hlodCacheBucket, [`${this.hlodCacheIdentifier}_bvh`]))[0];

    if (!data) {
        return null;
    }

    const header = new Uint32Array(data.buffer, 0, 3); // larger than necessary for backwards compatibility, can be reduced with the next cache version update
    const headerSize = header.byteLength;
    const nodesOffset = headerSize;
    const primitivesOffset = header[0];
    const primitivesSize = header[1];

    // BVHBuilder.NodeArray class expects this to be a separate buffer, hence the copy
    const nodes =  data.slice(nodesOffset, primitivesOffset).buffer;
    const primitives = new Int32Array(data.slice(primitivesOffset, primitivesOffset + primitivesSize).buffer);

    return new BVH(nodes, false, primitives, this.options.bvhOptions.consolidation);
};

OtgLoader.prototype.getCachedFragList = async function(loadContext) {

    const geomCache = this.viewer3DImpl.geomCache();
    const materialHashesByteStride = this.svf.materialHashes.byteStride;

    // Read extra material hashes from cache
    const extraMaterialHashes = (await geomCache.opfsGet(this.hlodCacheBucket, [`${this.hlodCacheIdentifier}_extra_material_hashes`]))?.[0];

    if (!extraMaterialHashes) {
        throw new Error("Failed to read extra material hashes from cache");
    }

    const extraMaterialHashesInt32 = new Uint32Array(extraMaterialHashes.buffer,0, extraMaterialHashes.length / 4);
    const numExtraMaterialHashes = extraMaterialHashesInt32[0];
    const extraMaterialStride = (4 + materialHashesByteStride); // 4 bytes for key, materialHashesByteStride for hash

    let currentPosition = 4;
    for (let i = 0; i < numExtraMaterialHashes; i++) {
        const key = extraMaterialHashesInt32[currentPosition / 4];
        let hash = binToPackedString(extraMaterialHashes, currentPosition + 4, materialHashesByteStride);
        this.svf.extraMatHashes[key] = hash;
        this.svf.materialHashes.hashes[key] = hash;

        currentPosition += extraMaterialStride;
    }
    this._extraMaterialHashesLoadedFromCache = true;
    if (numExtraMaterialHashes > 0) {
        this.tryToLoadExtraMaterials();
    }

    this.svf.cachedPolygonCounts = new Uint32Array(this.svf.metadata.stats.num_fragments);

    const iterator = this.model.getIterator();
    const bvh = iterator.getBVH();
    const manager = OutOfCoreTileManager.getManagerForIterator(iterator, this.model)[0];

    let chunksLoaded = 0;

    // initialize tasks for OutOfCoreTileManager
    bvh.traverseBreadthFirst((nodeIdx) => {

        manager.addCallbackTask(nodeIdx, async () => {
            const data = await this.loadCachedFragListChunk(nodeIdx);

            if (!data) {
                return;
            }

            manager.addCallbackTask(nodeIdx, () => {
                this.parseFragListChunk(nodeIdx, data, loadContext);

                manager.requestGeometryForNode(nodeIdx);

                if (++chunksLoaded === bvh.nodes.nodeCount) {
                    this.model.boxesLoadingDone();

                    // technically we could just do loadContext.onLoaderEvent("all_fragments") here,
                    // but to keep things consistent with the load from server routine we fire all three postLoad events
                    this.svf.postLoad(loadContext, "geometry_ptrs");
                    this.svf.postLoad(loadContext, "material_ptrs");
                    this.svf.postLoad(loadContext, "all_fragments");

                    // If the geomCache has finished downloading geometries and stopped processing before we finished parsing the fraglist,
                    // getNextBVHNodeToPrefetchGeometryFor will not be called, so initializeNode won't be called.
                    // here we start processing again, which will call getNextBVHNodeToPrefetchGeometryFor on any remaining nodes, which shouldn't
                    // do anything but call initializeNode.
                    this.viewer3DImpl.geomCache().startProcessing();
                }
            }, true, true);
        }, true, true);
    });
};

OtgLoader.prototype.getCachedConsolidationMap = async function() {
    if (this.consolidationMapLoadedFromCache) {
        return;
    }
    const geomCache = this.viewer3DImpl.geomCache();

    const data = (await geomCache.opfsGet(this.hlodCacheBucket, [`${this.hlodCacheIdentifier}_consolidation_map`]))[0];

    if (!data) {
        return;
    }

    this.model.setCachedConsolidationBlob(data);

    this.consolidationMapLoadedFromCache = true;
};

OtgLoader.prototype.cacheBVH = async function() {

    const geomCache = this.viewer3DImpl.geomCache();
    let bvhIterator = this.model.getIterator();
    const bvh = bvhIterator.getBVH();

    const headerSize = 12; // larger than necessary for backwards compatibility, can be reduced with the next cache version update
    const nodesOffset = headerSize;
    const primitivesOffset = nodesOffset + bvh.nodes.nodesRaw.byteLength;

    const buffer = new Uint8Array(primitivesOffset + bvh.primitives.byteLength);
    const bufferU32 = new Uint32Array(buffer.buffer);
    bufferU32[0] = primitivesOffset;
    bufferU32[1] = bvh.primitives.buffer.byteLength;
    buffer.set(new Uint8Array(bvh.nodes.nodesRaw), nodesOffset);
    buffer.set(getUInt8Buffer(bvh.primitives), primitivesOffset);

    return geomCache.opfsStore(this.hlodCacheBucket, [`${this.hlodCacheIdentifier}_bvh`], [buffer]);
};

OtgLoader.prototype.cacheFragList = async function() {
    const geomCache = this.viewer3DImpl.geomCache();

    const bvh = this.model.getIterator().getBVH();
    const nodes = bvh.nodes;
    const primitives = bvh.primitives;
    const frags = this.model.getFragmentList().fragments;

    const geometryHashesByteStride = this.svf.geomMetadata.byteStride;
    const materialHashesByteStride = this.svf.materialHashes.byteStride;

    const fragListHash = new Uint8Array(this.fragListHashIdentifier);
    const fragListHash16 = new Uint16Array(fragListHash.buffer);
    const fragListHash32 = new Uint32Array(fragListHash.buffer);

    const identifiers = [];
    const datas = [];

    // Write extra material hashes to cache
    const numExtraMaterialHashes = Object.keys(this.svf.extraMatHashes ?? {}).length;
    const extraMaterialHashes = new Uint8Array(numExtraMaterialHashes * (materialHashesByteStride + 4) + 4);
    const extraMaterialHashesInt32 = new Uint32Array(extraMaterialHashes.buffer);
    const extraMaterialStride = (4 + materialHashesByteStride); // 4 bytes for key, materialHashesByteStride for hash

    extraMaterialHashesInt32[0] = numExtraMaterialHashes;
    let keys = Object.keys(this.svf.extraMatHashes);
    let currentPosition = 4;
    for (let i = 0; i < numExtraMaterialHashes; i++) {
        extraMaterialHashesInt32[currentPosition / 4] = keys[i];
        packedToBin(this.svf.extraMatHashes[keys[i]], extraMaterialHashes, currentPosition + 4);
        currentPosition += extraMaterialStride;
    }
    identifiers.push(`${this.hlodCacheIdentifier}_extra_material_hashes`);
    datas.push(extraMaterialHashes);

    bvh.traverseBreadthFirst((nodeIdx) => {
        const primStart = nodes.getPrimStart(nodeIdx);
        const primCount = nodes.getPrimCount(nodeIdx);

        const stride = 4 * primCount;
        const buffer = new ArrayBuffer(23 * stride + primCount * geometryHashesByteStride + primCount * materialHashesByteStride);
        const fragId2dbId = new Uint32Array(buffer, 0, primCount);
        const geomDataIndexes = new Uint32Array(buffer, stride, primCount);
        const materials = new Uint32Array(buffer, 2 * stride, primCount);
        const flags = new Uint32Array(buffer, 3 * stride, primCount);
        const boxes = new Float32Array(buffer, 4 * stride, 6 * primCount);
        const transforms = new Float32Array(buffer, 10 * stride, 12 * primCount);
        const polygonCounts = new Uint32Array(buffer, 22 * stride, primCount);
        const geometryHashes = new Uint8Array(buffer, 23 * stride, primCount * geometryHashesByteStride);
        const materialHashes = new Uint8Array(buffer, 23 * stride + primCount * geometryHashesByteStride, primCount * materialHashesByteStride);

        // collect data
        for (let i = 0; i < primCount; i++) {
            const fragId = primitives[primStart + i];
            fragId2dbId[i] = frags.fragId2dbId[fragId];
            geomDataIndexes[i] = frags.geomDataIndexes[fragId];
            materials[i] = frags.materials[fragId];
            flags[i] = frags.visibilityFlags[fragId] & MeshFlags.MESH_NOT_FOR_DISPLAY; // this is the only flag that comes from the downloaded fraglist. Everything else is runtime-generated
            polygonCounts[i] = this.svf.extraFragInfo.getPolygonCount(fragId);

            for (let j = 0; j < 6; j++) {
                boxes[i * 6 + j] = frags.boxes[fragId * 6 + j];
            }

            for (let j = 0; j < 12; j++) {
                transforms[i * 12 + j] = frags.transforms[fragId * 12 + j];
            }

            const geomId = geomDataIndexes[i];
            if (geomId !== 0) {
                // We use three different sources for geometry hashes. If a fragment
                // has already been activated, we will be able to take it from the geometry
                // list. If for some reason the fragment was not activated, we can directly
                // get it from the geometry hashes in the OTG loader, but in some cases these
                // will be freed after loading. In those cases, we keep the geometry hashes
                // in a separate _notLoadedGeomHashes map.
                let hash = this.model.getGeometryList().getGeometry(geomId)?.hash ??
                           this.svf.getGeometryHash(geomId) ??
                           this._notLoadedGeomHashes[geomId];
                packedToBin(hash, geometryHashes, i * geometryHashesByteStride);
            }
            packedToBin(this.svf.materialHashes.hashes[materials[i]], materialHashes, i * materialHashesByteStride);
        }

        fragListHash32[4] = nodeIdx; // encode nodeId in last 4 bytes
        identifiers.push(bin16ToPackedString(fragListHash16));
        datas.push(new Uint8Array(buffer));

    });

    return geomCache.opfsStore(this.hlodCacheBucket, identifiers, datas);
};

OtgLoader.prototype.loadCachedFragListChunk = async function(chunkId) {
    const geomCache = this.model.loader.viewer3DImpl.geomCache();

    const fragListHash = new Uint8Array(this.fragListHashIdentifier);
    const fragListHash16 = new Uint16Array(fragListHash.buffer);
    const fragListHash32 = new Uint32Array(fragListHash.buffer);

    fragListHash32[4] = chunkId; // encode chunkId in last 4 bytes

    return (await geomCache.opfsGet(this.hlodCacheBucket, [bin16ToPackedString(fragListHash16)], true))[0];
};

// this should mirror Otg.js:readOneFragment
OtgLoader.prototype.parseFragListChunk = function(chunkId, data, loadContext) {
    const model = this.model;
    const svf = model.loader.svf;

    const frags = model.getFragmentList().fragments;
    const geometryHashesByteStride = svf.geomMetadata.byteStride;
    const materialHashesByteStride = svf.materialHashes.byteStride;

    const bvh = model.getIterator().getBVH();
    const nodes = bvh.nodes;
    const primitives = bvh.primitives;
    const primStart = nodes.getPrimStart(chunkId);
    const primCount = nodes.getPrimCount(chunkId);
    const stride = 4 * primCount;

    const chunk = {
        fragId2dbId: new Uint32Array(data.buffer, 0, primCount),
        geomDataIndexes: new Uint32Array(data.buffer, stride, primCount),
        materials: new Uint32Array(data.buffer, 2 * stride, primCount),
        flags: new Uint32Array(data.buffer, 3 * stride, primCount),
        boxes: new Float32Array(data.buffer, 4 * stride, 6 * primCount),
        transforms: new Float32Array(data.buffer, 10 * stride, 12 * primCount),
        polygonCounts: new Uint32Array(data.buffer, 22 * stride, primCount),
        geometryHashes: new Uint8Array(data.buffer, 23 * stride, primCount * geometryHashesByteStride),
        materialHashes: new Uint8Array(data.buffer, 23 * stride + primCount * geometryHashesByteStride, primCount * materialHashesByteStride),
    };

    for (let i = 0; i < primCount; i++) {
        const fragId = primitives[primStart + i];
        frags.fragId2dbId[fragId] = chunk.fragId2dbId[i];
        frags.geomDataIndexes[fragId] = chunk.geomDataIndexes[i];
        frags.materials[fragId] = chunk.materials[i];
        svf.cachedPolygonCounts[fragId] = chunk.polygonCounts[i];

        if (chunk.flags[i] & MeshFlags.MESH_NOT_FOR_DISPLAY) {
            frags.visibilityFlags[fragId] |= MeshFlags.MESH_NOT_FOR_DISPLAY;
            // LMV-6230: Unset MESH_VISIBLE flag in favor of setting MESH_HIDE as Model Browser controls visibility using MESH_VISIBLE
            frags.visibilityFlags[fragId] &= ~MeshFlags.MESH_VISIBLE;
        }

        for (let j = 0; j < 6; j++) {
            frags.boxes[fragId * 6 + j] = chunk.boxes[i * 6 + j];
        }

        for (let j = 0; j < 12; j++) {
            frags.transforms[fragId * 12 + j] = chunk.transforms[i * 12 + j];
        }

        if (!svf.geomMetadata.hashes[frags.geomDataIndexes[fragId]]) {
            svf.geomMetadata.hashes[frags.geomDataIndexes[fragId]] = binToPackedString(chunk.geometryHashes, i * geometryHashesByteStride, geometryHashesByteStride);
        }

        if (!svf.materialHashes.hashes[frags.materials[fragId]]) {
            svf.materialHashes.hashes[frags.materials[fragId]] = binToPackedString(chunk.materialHashes, i * materialHashesByteStride, materialHashesByteStride);
        }

        if (this.loadContext.buildDbId2FragIdMap) {
            svf.addDbIdReference(fragId, frags.fragId2dbId[fragId]);
        }

        loadContext.onLoaderEvent("fragment", fragId);
        this.svf.fragments.numLoaded++;
    }
};

OtgLoader.prototype.cacheConsolidationMap = async function() {
    const data = this.model.getConsolidationBlobForCaching();

    if (!data) {
        return;
    }

    const geomCache = this.viewer3DImpl.geomCache();

    return geomCache.opfsStore(this.hlodCacheBucket, [`${this.hlodCacheIdentifier}_consolidation_map`], [data]);
};

OtgLoader.prototype.setHLODCachedFlag = function(value) {
    const identifier = this.hlodCacheBucket + this.hlodCacheIdentifier;
    LocalStorage.setItem(identifier, value);
};

OtgLoader.prototype.getHLODCachedFlag = function() {
    const identifier = this.hlodCacheBucket + this.hlodCacheIdentifier;
    return JSON.parse(LocalStorage.getItem(identifier));
};

//Attempts to turn on display of a received fragment.
//If the geometry or material is missing, issue requests for those
//and delay the activation. Once the material or mesh comes in, they
//will attempt this function again.
OtgLoader.prototype.tryToActivateFragment = function(fragId, whichCaller) {

    var svf = this.svf;
    var rm = this.model;

    //Was loading canceled?
    if (!rm)
        return;

    var geomId = svf.fragments.geomDataIndexes[fragId];

    const skipLoad = svf.dbIdFilter && !svf.dbIdFilter[svf.fragments.fragId2dbId[fragId]]
        || this._selectiveLoadingController.isActive && !this._selectiveLoadingController.isFragmentPassing(fragId)
        || svf.loadOptions.skipHiddenFragments && (svf.fragments.visibilityFlags[fragId] & MeshFlags.MESH_NOT_FOR_DISPLAY);

    if (skipLoad) {
        rm.getFragmentList().setFlagFragment(fragId, MeshFlags.MESH_NOTLOADED, true);
        rm.getFragmentList().setFragOff(fragId, true);
        this.trackGeomLoadProgress(svf, fragId, false);

        // If we are not loading the geometry, we have to preserve the geometry hash,
        // as it will later be needed when caching the frag list
        this._notLoadedGeomHashes[geomId] = this.svf.getGeometryHash(geomId);
        return;
    }

    var haveToWait = false;

    //The tryToActivate function can be called up to three times, until all the
    //needed parts are received.
    // Before we can safely consider a fragment as finished, we must make sure that there are no pending
    // tryToActivate(fragId, "material") or "geometry" calls that will come later.

    //1. Check if the material is done

    var material = null;
    var materialId = svf.fragments.materials[fragId];
    var matHash = svf.getMaterialHash(materialId);

    if (matHash !== null) {
        material = this.findOrLoadMaterial(rm, matHash);
        if (material === null) {

            if (whichCaller === "fragment") {
                //Material is not yet available, so we will delay turning on the fragment until it arrives
                this.pendingMaterials.get(matHash).push(fragId);
                this.pendingRequests++;
            }

            if (whichCaller !== "material") {
                haveToWait = true;
            } else {
                //it means the material failed to load, so we won't wait for it.
            }
        } else {
            // Materials are shared across models, so for already loaded materials with the same hash we need to ref count the current model
            if (whichCaller === "fragment") {
                this.viewer3DImpl.matman()._addMaterialRef(material, this.model.id);
            }
        }
    } else {
        // We don't have to wait if material hash is null, as this indicates an invalid material index.
        console.warn("Material hash is null for fragment ", fragId);
    }

    //2. Check if the mesh is done

    // Note that otg translation may assign geomIndex 0 to some fragments by design.
    // This happens when the source fragment geometry was degenerated.
    // Therefore, we do not report any warning or error for this case.
    //don't block overall progress because of this -- mark the fragment as success.
    if (geomId === 0) {
        if (material || whichCaller === "material") {
            // A fragment with null-geom may still have a material. If so, we wait for the material before we consider it as finished.
            // This makes sure that we don't count this fragment twice. Note that we cannot just check whichCaller==="fragment" here:
            // This would still cause errors if the material comes in later after onGeomLoadDone().
            this.trackGeomLoadProgress(svf, fragId, false);
        }
        return;
    }

    var geom = rm.getGeometryList().getGeometry(geomId);
    if (!geom) {

        if (whichCaller === "fragment") {
            //Mesh is not yet available, so we will request it and
            //delay turning on the fragment until it arrives
            this.loadGeometry(geomId, fragId);
        }

        haveToWait = true;
    } else {
        if (whichCaller === "fragment") {
            rm.getGeometryList().addInstance(geomId);
        }
    }

    if (haveToWait)
        return;

    //if (this.options.createWireframe)
    //    createWireframe(geom, this.tmpMatrix);

    //We get the matrix from the fragments and we pass it back into setupMesh
    //with the activateFragment call, but this is to maintain the
    //ability to add a plain THREE.Mesh -- otherwise it could be simpler
    rm.getFragmentList().getOriginalWorldMatrix(fragId, this.tmpMatrix);

    var m = this.viewer3DImpl.setupMesh(rm, geom, matHash, this.tmpMatrix);

    //If there is a placement transform, we tell activateFragment to also recompute the
    //world space bounding box of the fragment from the raw geometry model box, for a tighter
    //fit compared to what we get when loading the fragment list initially.
    rm.activateFragment(fragId, m, !!svf.placementTransform);

    // pass new fragment to Geometry cache to update priority
    // TODO: Check if we can determine the bbox earlier, so that we can also use it to prioritize load requests
    //       from different OtgLoaders.
    this.viewer3DImpl.geomCache().updateGeomImportance(rm, fragId);

    this.trackGeomLoadProgress(svf, fragId, false);

};

OtgLoader.prototype.tryToLoadExtraMaterials = function () {

    if (this.loadFromCache && !this._extraMaterialHashesLoadedFromCache) {
        return;
    }
    var svf = this.svf;
    var rm = this.model;

    // Was loading canceled?
    if (!rm)
        return;

    // Was extra materials loading kicked off already?
    if (svf.extraMaterialLoaded)
        return;

    svf.extraMaterialLoaded = true;
    let allExtraMaterialsFound = true;
    for (const matHash of Object.values(svf.extraMatHashes)) {
        const mat = this.findOrLoadMaterial(rm, matHash);
        if (!mat) {
            this.viewpointMatHashes.add(matHash);
            allExtraMaterialsFound = false;
        }
    }

    if (allExtraMaterialsFound) {
        this.onOperationComplete();
    }
};

OtgLoader.prototype.onModelRootLoadDone = function(svf) {

    // Mark svf as Oscar-file. (which uses sharable materials and geometry)
    svf.isOTG = true;

    svf.geomMetadata.hashToIndex = new Map();

    svf.failedFrags = {};
    svf.failedMeshes = {};
    svf.failedMaterials = {};

    // counts fully loaded fragments (including geometry and material)
    svf.fragsLoaded = 0;

    // number of loaded fragments (also the ones for which we didn't load material and geom yet)
    svf.fragsLoadedNoGeom = 0;

    svf.nextRepaintPolys = 0;
    svf.numRepaints = 0;

    svf.urn = this.svfUrn;
    svf.acmSessionId = this.acmSessionId;

    svf.basePath = this.basePath;

    svf.loadOptions = this.options || {};

    var t1 = Date.now();
    logger.log("SVF load: " + (t1 - this.t0));

    // Create the API Model object and its render proxy
    var model = this.model = new Model(svf);
    model.loader = this;

    model.initialize();

    this._selectiveLoadingController.onModelRootReady(model);

    this.t1 = t1;

    logger.log("scene bounds: " + JSON.stringify(svf.bbox));

    var metadataStats = {
        category: "metadata_load_stats",
        urn: svf.urn,
        has_topology: !!svf.topology,
        has_animations: !!svf.animations,
        materials: svf.metadata.stats.num_materials,
        is_mobile: isMobileDevice()
    };
    logger.track(metadataStats);

    this.viewer3DImpl.signalProgress(5, ProgressState.ROOT_LOADED, this.model);

    svf.handleHashListRequestFailures(this.loadContext);

    svf.propDbLoader = new PropDbLoader(this.sharedDbPath, this.model, this.viewer3DImpl.api);

    // Ideally this would be just this._lineageUrn = model.getDocumentNode().lineageUrn(), but:
    // getDocumentNode() can be null (there was a customer report, we don't know how that happened, see also https://git.autodesk.com/A360/firefly.js/pull/6349),
    // in which we use the identical svf.urn. However, we can not simply always use svf.urn, because it is not set when loading models locally (e.g. DiffTool tests)
    // Also models uploaded directly to OSS (not via WipDM) don't have a lineage, so lineageUrn returns null.
    this._lineageUrn = model.getDocumentNode()?.lineageUrn();
    if (!this._lineageUrn) {
        this._lineageUrn = BubbleNode.parseLineageUrnFromDecodedUrn(Autodesk.Viewing.fromUrlSafeBase64(svf.urn));
    }

    // We don't call invalidate here: At this point, the model is not added to the viewer yet (see onSuccess()
    // in Viewer3D.loadModel). So, invalidating would just let other models flicker.
};


// Returns geometry loading progress in integer percent
function getProgress(svf) {
    return Math.floor(100 * svf.fragsLoaded / svf.metadata.stats.num_fragments);
}

// Called whenever a geom load request is finished or or has failed.
OtgLoader.prototype.trackGeomLoadProgress = function(svf, fragId, failed) {

    if (failed) {
        //TODO: failedFrags can be removed, once we make sure
        //that we are not calling this function with the same fragment twice.
        if (svf.failedFrags[fragId]) {
            console.log("Double fail", fragId);
            return;
        }

        svf.failedFrags[fragId] = 1;
    }

    // Inc geom counter and track progress in percent
    var lastPercent = getProgress(svf);

    svf.fragsLoaded++;

    var curPercent = getProgress(svf);

    // Signal progress, but not for each single geometry. Just if the percent value actually changed.
    if (curPercent > lastPercent) {
        this.viewer3DImpl.signalProgress(curPercent, ProgressState.LOADING, this.model);
    }

    //repaint every once in a while -- more initially, less as the load drags on.
    var geomList = this.model.getGeometryList();
    if (geomList.geomPolyCount > svf.nextRepaintPolys) {
        if (!this.firstPixelTimestamp) {
            // this is imprecise: between now and the first pixel can be an arbitrary amount of events and also rendering itself.
            this.firstPixelTimestamp = Date.now();
        }
        svf.numRepaints ++;
        svf.nextRepaintPolys += 100000 * Math.pow(1.5, svf.numRepaints);
        this._requestRepaint();
    }

    // If this was the last geom to receive...
    if (svf.fragsLoaded === svf.metadata.stats.num_fragments) {
        // Signal that we are done with mesh loading
        this.onOperationComplete();
    }

    this.trackOnDemandLoadProgress();
};

OtgLoader.prototype.trackFragmentListLoadProgress = function() {

    var svf = this.svf;
    function getFragListLoadProgress(svf) {
        return Math.floor(100 * svf.fragsLoadedNoGeom / svf.metadata.stats.num_fragments);
    }
    var lastPercent = getFragListLoadProgress(svf);
    svf.fragsLoadedNoGeom++;
    var percent = getFragListLoadProgress(svf);

    if (percent > lastPercent) {
        this.options.onFragmentListLoadProgress(this.model, percent);
    }
};

OtgLoader.prototype.onOperationComplete = function() {
    this.operationsDone++;

    //Destroy the loader if everything we are waiting to load is done
    if (this.operationsDone === 4)
        this.onGeomLoadDone();
};


OtgLoader.prototype.onMaterialLoaded = function(matObj, matHash, matId) {

    if (!this.loading) {
        // This can only happen if dtor was called while a loadMaterial call is in progress.
        // In this case, we can just ignore the callback.
        return;
    }

    // get fragIds that need this material
    var fragments = this.pendingMaterials.get(matHash);

    // Note that onMaterialLoaded is triggered by an EvenListener on geomCache. So, we may also receive calls
    // for materials that we are not wait for, just because other loaders have requested them in parallel.
    // In this case, we must ignore the event.
    if (!fragments) {
        return;
    }

    var matman = this.viewer3DImpl.matman();

    if (matObj) {

        matObj.hash = matHash;
        try {
            var surfaceMat = matman.convertSharedMaterial(this.model, matObj, matHash);

            TextureLoader.loadMaterialTextures(this.model, surfaceMat, this.viewer3DImpl);

            if (matman.hasTwoSidedMaterials()) {
                this.viewer3DImpl.renderer().toggleTwoSided(true);
            }
        } catch (e) {
            this.svf.failedMaterials[matHash] = 1;
        }

    } else {

        this.svf.failedMaterials[matHash] = 1;

    }

    this.pendingMaterials.delete(matHash);

    for (var i=0; i < fragments.length; i++) {
        this.pendingRequests--;
        this.tryToActivateFragment(fragments[i], "material");
    }

    this.viewer3DImpl._signalNewMaterialAdded(matObj, this.model);

    // All materials including the override materials due to viewpoints have also been loaded
    if (this.viewpointMatHashes.has(matHash)) {
        this.viewpointMatHashes.delete(matHash);
        if (this.viewpointMatHashes.size === 0) {
            this.onOperationComplete();
        }
    }
};

OtgLoader.prototype.findOrLoadMaterial = function(model, matHash) {
    // Early out if matHash is not valid, as `makeSharedResourcePath()` will throw an error.
    if (!matHash) {
        console.error("findOrLoadMaterial called with invalid material hash (null).");
        return null;
    }

    //check if it's already requested, but the request is not complete
    //
    // NOTE: If another OTG loader adds this material during loading, matman.findMaterial(..) may actually succeed already - even if we have a pending request.
    //       However, we consider the material as missing until the request is finished. In this way, only 2 cases are possible:
    //        a) A material was already loaded on first need => No material requests
    //        b) We requested the material => Once the request is finished, tryToActivate() will be triggered
    //           for ALL fragments using the material - no matter whether the material was added meanwhile by someone else or not.
    //
    //       If we would allow to get materials earlier, it would get very confusing to find out when a fragment is actually finished:
    //       Some fragments would be notified when the load request is done, but some would not - depending on timing.
    if (this.pendingMaterials.has(matHash)) {
        return null;
    }

    var svf = this.svf;

    //Check if it's already in the material manager
    var matman = this.viewer3DImpl.matman();
    var mat = matman.findMaterial(model, matHash);

    if (mat)
        return mat;

    //If it's not even requested yet, kick off the request
    this.pendingMaterials.set(matHash, []);

    var url = svf.makeSharedResourcePath(this.loadContext.otg_cdn, "materials", matHash);

    // load geometry or get it from cache
    var geomCache = this.viewer3DImpl.geomCache();
    geomCache.requestMaterial(url, undefined, matHash, undefined, this.queryParams, this._lineageUrn);

    return null;
};

OtgLoader.prototype.loadGeometry = function(geomIdx, fragId) {

    var svf = this.svf;

    ++this.pendingRequests;

    //check if it's already requested, but the request is not complete
    var frags = this.pendingMeshes[geomIdx];
    if (frags) {
        frags.push(fragId);
        return;
    }

    //If it's not even requested yet, kick off the request
    this.pendingMeshes[geomIdx] = [fragId];

    var geomHash = svf.getGeometryHash(geomIdx);

    svf.geomMetadata.hashToIndex.set(geomHash, geomIdx);

    // When we are loading from the cache, we will only add a limited number of requests to the queue.
    // Once the BVH has been loaded from the cache, we will start fetching geometries via the BVH and
    // no longer need the priority queue. Adding everything to the queue would just slow down the loading.
    // We only add a small number of geometries, so that geometry loading can already start and the user sees
    // at least some visual progress, while the BVH is being loaded from the cache.
   let addToQueue = !(this.loadFromCache && this.pendingRequests > MAX_PRIORITY_QUEUE_REQUEST_WHEN_LOADING_FROM_CACHE);

    var url = svf.makeSharedResourcePath(this.loadContext.otg_cdn, "geometry", geomHash);

    // load geometry or get it from cache
    const geomCache = this.viewer3DImpl.geomCache();
    geomCache.requestGeometry(url, undefined, geomHash, undefined, this.queryParams, this._lineageUrn, addToQueue);
};

OtgLoader.prototype.onMeshError = function(geomHash) {

    this.svf.failedMeshes[geomHash] = 1;

    var geomIdx = this.svf.geomMetadata.hashToIndex.get(geomHash);

    var frags = this.pendingMeshes[geomIdx];

    if (!frags) {
        // The failed mesh has been requested by other loaders, but not by this one.
        return;
    }

    for (var i=0; i < frags.length; i++) {
        this.trackGeomLoadProgress(this.svf, frags[i], true);
        this.pendingRequests--;
    }

    this.svf.geomMetadata.hashToIndex.delete(geomHash);
    this.pendingMeshes[geomIdx] = null;
};

OtgLoader.prototype.onMeshReceived = function(geom) {

    var rm = this.model;

    if (!rm) {
        console.warn("Received geometry after loader was done. Possibly leaked event listener?", geom.hash);
        return;
    }

    var gl = rm.getGeometryList();

    var geomId = this.svf.geomMetadata.hashToIndex.get(geom.hash);

    //It's possible this fragment list does not use this geometry
    if (geomId === undefined)
        return;

    var geomAlreadyAdded = gl.hasGeometry(geomId);

    var frags = this.pendingMeshes[geomId];

    //TODO: The instance count implied by frags.length is not necessarily correct
    //because the fragment list is loaded progressively and the mesh could be received
    //before all the fragments that reference it. Here we don't need absolute correctness.
    if (!geomAlreadyAdded)
        gl.addGeometry(geom, (frags && frags.length) || 1, geomId, this.viewer3DImpl.geomCache());
    else
        return; //geometry was already received, possibly due to sharing with the request done by another model loader in parallel

    if (this.svf.loadDone && !this.options.onDemandLoading && !this._selectiveLoadingController.isActive) {
        console.error("Geometry received after load was done");
    }

    this.svf.geomMetadata.hashToIndex.delete(geom.hash);
    this.pendingMeshes[geomId] = null;

    if (frags) {
        for (var i=0; i < frags.length; i++) {
            this.pendingRequests--;
            this.tryToActivateFragment(frags[i], "geom");
        }
    }

    this.viewer3DImpl.signalNewGeometryAdded(geomId, this.model);
};


OtgLoader.prototype.onGeomLoadDone = async function() {

    // Unless selective loading is active, stop listening to geometry receive events.
    // Since all our geometry is loaded, any subsequent geom receive
    // events are just related to requests from other loaders.
    if (!this.options.onDemandLoading && !this._selectiveLoadingController.isActive) {
        this.removeMeshReceiveListener();
    }
    this.svf.loadDone = true;

    //Note that most materials are probably done already as their geometry
    //is received, so this logic will most likely just trigger the textureLoadComplete event.
    TextureLoader.loadModelTextures(this.model, this.viewer3DImpl);

    //If we were asked to just load the model root / metadata, bail early.
    if (this.options.skipMeshLoad) {
        this.currentLoadPath = null;
        this.viewer3DImpl.onLoadComplete(this.model);
        return;
    }

    // We need to keep a copy of the original fragments
    // transforms in order to restore them after explosions, etc.
    // the rotation/scale 3x3 part.
    // TODO: consider only keeping the position vector and throwing out
    //
    //delete this.svf.fragments.transforms;

    // Release that won't be used. the on demand loading won't call this anyway.
    this.svf.fragments.entityIndexes = null;
    this.pendingMeshes = new Array();
    if (!this.options.onDemandLoading && !this._selectiveLoadingController.isActive) {
        this.svf.geomMetadata.hashes = null;
    }

    var t2 = Date.now();
    var msg = "Fragments load time: " + (t2 - this.t1);
    this.loadTime = t2 - this.t0;

    var firstPixelTime = this.firstPixelTimestamp - this.t0;
    msg += ' (first pixel time: ' + firstPixelTime + ')';

    logger.log(msg);

    if (this.loadFromCache) {
        await this.getCachedConsolidationMap();
    }

    // Run optional consolidation step
    if (!USE_OUT_OF_CORE_TILE_MANAGER && this.options.useConsolidation) {
        await this.viewer3DImpl.consolidateModel(this.model, this.options.consolidationMemoryLimit);
    }

    var modelStats = {
        category: "model_load_stats",
        is_f2d: false,
        has_prism: this.viewer3DImpl.matman().hasPrism,
        load_time: this.loadTime,
        geometry_size: this.model.getGeometryList().geomMemory,
        meshes_count: this.svf.metadata.stats.num_geoms,
        fragments_count: this.svf.metadata.stats.num_fragments,
        urn: this.svfUrn
    };
    if (firstPixelTime > 0) {
        modelStats['first_pixel_time'] = firstPixelTime; // time [ms] from SVF load to first geometry rendered
    }
    logger.track(modelStats, true);

    const geomList = this.model.getGeometryList();
    const dataToTrack = {
        load_time: this.loadTime,
        polygons: geomList.geomPolyCount,
        fragments: this.model.getFragmentList().getCount(),
        mem_usage: geomList.gpuMeshMemory,
        time_to_first_pixel: firstPixelTime,
        viewable_type: '3d',
        url: this.currentLoadPath,
        urn: this.svfUrn,
        loaded_from_cache: this.loadFromCache,
    };
    avp.analytics.track('viewer.model.loaded', dataToTrack);

    this.currentLoadPath = null;

    this.viewer3DImpl.onLoadComplete(this.model);

    // Loading and saving the FragList/BVH from cache is currently not supported when a selective loading controlled is
    // enabled since in those cases only a partial dataset would be stored in the cache.
    if (!this.loadFromCache && BVH_CACHING && !this.options?.disableCache && !this._selectiveLoadingController.isActive) {
        // Cache BVH, fragment list and consolidation map the first time the model is consolidated
        let writeToCache = async () => {
            const cachingPromises = [
                this.cacheBVH(),
                this.cacheFragList(),
                this.cacheConsolidationMap()
            ];
            if ((await Promise.all(cachingPromises)).every((success) => success)) {
                this.setHLODCachedFlag(true);
            }
        };

        if (this.model.isConsolidated()) {
            writeToCache();
        } else {
            this.consolidationEventHandler = async (event) => {
                if (event.model === this.model) {
                    writeToCache();

                    this.viewer3DImpl.api.removeEventListener(MODEL_CONSOLIDATION_ENABLED, this.consolidationEventHandler);
                    this.consolidationEventHandler = null;
                }
            };

            this.viewer3DImpl.api.addEventListener(MODEL_CONSOLIDATION_ENABLED, this.consolidationEventHandler);
        }
    }
};

OtgLoader.prototype.loadPropertyDb = function() {
    this.svf.propDbLoader.load();
};


OtgLoader.prototype.is3d = function() {
    return true;
};

// Returns promise that resolves to null on failure.
OtgLoader.loadOnlyOtgRoot = function(path, options) {

    // create loadContext
    const basePath    = getBasePath(path);
    const queryParams = getQueryParams(options);
    const loadContext = createLoadContext(options, basePath, queryParams);

    // init otg package
    const otg = new OtgPackage();
    otg.basePath = basePath;

    // Just load root json and process its metadata
    const url = pathToURL(path);
    return new Promise((resolve, reject) => {

        loadContext.onFailureCallback = () => resolve(null);
        otg.loadAsyncResource(loadContext, url, "json", function(data) {
            otg.metadata = data;
            otg.manifest = data.manifest;

            // Set numGeoms. Note that this must happen before creating the GeometryList.
            otg.numGeoms = otg.metadata.stats.num_geoms;

            otg.processMetadata(loadContext);

            resolve(otg);
        });
    });
};

/**
 * This function will trigger consolidation, once all necessary information is available.
 *
 * This function must only by called, when the OutOfCoreTileManager is enabled.
 */
OtgLoader.prototype.tryEarlyConsolidation = async function() {
    if (this.loadFromCache) {
        await this.getCachedConsolidationMap();
        await this.viewer3DImpl._consolidateModelInternal(this.model);
        this.viewer3DImpl.invalidate(true, true, true);

    } else {
        if (this.fragmentDataLoaded && this.svf.extraFragInfo) {
            // Both fragment list and fragment extras have been loaded, we no longer
            // need to throttle downloading the geometries
            this.viewer3DImpl.geomCache().stopThrottleDownload();

            // Update the bounding boxes (we wait with the update until all fragments and the fragments extra
            // have been loaded
            this.model.setFragmentBoundingBoxes(this.svf.extraFragInfo.boxes,
                                                this.svf.extraFragInfo.boxStride);

            await this.viewer3DImpl._consolidateModelInternal(this.model);

            // traverse bvh, call tile manager's requestGeometryForNode on each
            const iterator = this.model.getIterator("BVH");
            const bvh = iterator.getBVH();
            const manager = OutOfCoreTileManager.getManagerForIterator(iterator, this.model)[0];
            bvh.traverseBreadthFirst((nodeIdx) => {
                manager.requestGeometryForNode(nodeIdx);
            });

            // Update the selective loading controller when the bounding volume hierarchy is available
            this._selectiveLoadingController.onBoundingVolumeHierarchyReady();
            this._requestRepaint();

            // This is the onOperationComplete for the "otg_root" event. When using USE_OUT_OF_CORE_TILE_MANAGER,
            // we delay this event, until after loading of fragments extra, because the event for "otg_root" is only
            // be triggered after the BVH has been computed and this now happens in the consolidateModel function above
            // which depends on the fragment extras being available.
            this.onOperationComplete();
        }
    }
};

// Right after model loading, some fragment data are not available yet.
// E.g. fragments.fragId2DbId will initially contain only zeros and graudally filled afterwards.
// Note that waiting for fragment data doesn't guarantee yet that geometry and materials are already loaded too.
//  @returns {boolean} True when finished, false when canceled or failed.
OtgLoader.prototype.waitForFragmentData = async function() {
    if (this.fragmentDataLoaded) {
        return true;
    }

    const viewer = this.viewer3DImpl.api;
    return new Promise((resolve) => {

        const clearListeners = () => {
            viewer.removeEventListener(av.MODEL_ROOT_LOADED_EVENT, onLoad);
            viewer.removeEventListener(av.MODEL_UNLOADED_EVENT, onUnload);
        };

        const onLoad = (e) => {
            if (e.model.loader !== this) {
                return;
            }

            clearListeners();
            resolve(true);
        };

        const onUnload = (e) => {
            if (e.model.loader !== this) {
                return;
            }

            clearListeners();
            resolve(false);
        };

        // Use the following method.
        // av.waitForCompleteLoad(viewer, options)
        viewer.addEventListener(av.MODEL_ROOT_LOADED_EVENT, onLoad);
        viewer.addEventListener(av.MODEL_UNLOADED_EVENT, onUnload);
    });
};

/**
 * Check if on demand loading is done and send event in that case
 * Fires Autodesk.Viewing.FRAGMENTS_LOADED_EVENT if loading is done
 */
OtgLoader.prototype.trackOnDemandLoadProgress = function() {
    if (this.svf.loadDone && this.fragLoadingInProgress === false && this.pendingRequests === 0)
        this.viewer3DImpl.api.dispatchEvent({ type: av.FRAGMENTS_LOADED_EVENT, loader: this, model: this.model });
};

/**
 * Interface called by SelectiveLoadingController
 */
OtgLoader.prototype.evaluate = function(fragmentID) {
    if (!this.model || this.options.skipMeshLoad) {
        return;
    }
    // This case is used if fragments are evaluated as they come in.
    if (fragmentID !== undefined) {
        this.tryToActivateFragment(fragmentID, 'fragment');
        return;
    }
    // This branch is used if filtering is deferred, i.e. if we need to wait for the property db.
    // TODO: This only works for the initial filter execution at the moment. Filter updates are not supported yet.
    const fragmentList = this.model.getFragmentList();
    const fragmentLength = fragmentList.fragments.length;
    for (let fragmentID = 0; fragmentID < fragmentLength; ++fragmentID) {
        this.tryToActivateFragment(fragmentID, 'fragment');
    }
};

/**
 * Activates given fragments and loads their referenced geometries and materials if necessary.
 * Already activated fragments are skipped and calls are ignored during initial load
 *
 * @param {number[]} fragIds - IDs of fragments to load
 * @returns {number} Number of loaded fragments
 */
OtgLoader.prototype.loadFragments = function(fragIds) {
    // TODO: Make this work while initial load is happening
    if (!this.svf.loadDone) {
        logger.warn("Trying to call loadFragments while model is not fully loaded yet.");
        return 0;
    }

    // Reset to get repaints while loading
    this.svf.nextRepaintPolys = 0;
    this.svf.numRepaints = 0;

    let fragsLoaded = 0;
    const fl = this.model.getFragmentList();

    this.fragLoadingInProgress = true;
    for (const fragId of fragIds) {
        if (fl.isFlagSet(fragId, avp.MeshFlags.MESH_NOTLOADED)) {
            fl.setFlagFragment(fragId, avp.MeshFlags.MESH_NOTLOADED, false);
            fl.setFragOff(fragId, false);
            fl.setVisibility(fragId, true);

            this.tryToActivateFragment(fragId, 'fragment');
            ++fragsLoaded;
        }
    }
    this.fragLoadingInProgress = false;

    // trigger load event in case no materials or geometries need to be fetched
    if (fragsLoaded)
        this.trackOnDemandLoadProgress();

    return fragsLoaded;
};

FileLoaderManager.registerFileLoader("json", ["json"], OtgLoader);
