
import { logger } from "../logger/Logger";
import * as et from "../application/EventTypes";
import { SYNTHETIC_ROOT_ID } from "../globals";

/** @import {Viewer3DImpl} from "../application/Viewer3DImpl" */
/** @import {Model} from "../application/Model" */
/** @import {InstanceTree} from "../wgs/scene/InstanceTree" */

export class VisibilityManager {

    /** @type {Viewer3DImpl} */
    viewerImpl;

    /** @type {Model} */
    model;

    /**
     * Keeps track of hidden nodes. Only applies when there's no isolated node being tracked.
     *
     * @type {Set<number>}
     */
    hiddenNodes = new Set();

    /**
     * Keeps track of isolated nodes
     *
     * @type {Set<number>}
     */
    isolatedNodes = new Set();

    /**
     * @param {Viewer3DImpl} viewerImpl
     * @param {Model} model
     */
    constructor(viewerImpl, model) {
        this.viewerImpl = viewerImpl;

        //Currently the visibility manager works on a single model only
        //so we make this explicit here.
        this.model = model;
    }

    /**
     * @returns {InstanceTree|null}
     */
    getInstanceTree() {
        if (this.model) return this.model.getInstanceTree();
        // must never be the case, but maybe somewhere in the outter world...
        return null;
    }

    /**
     * @returns {number[]}
     */
    getIsolatedNodes() {
        return Array.from(this.isolatedNodes);
    }

    /**
     * @returns {number[]}
     */
    getHiddenNodes() {
        return Array.from(this.hiddenNodes);
    }

    /**
     * @param {boolean} visible - visible flag applied to all dbIds/fragments
     * @param {boolean} [trackIsolation=false] - optional flag to track isolation, defaults to false
     * @param {number|null} [rootId=null] - optional root ID to track, defaults to null
     */
    setAllVisibility(visible, trackIsolation = false, rootId = null) {

        this.isolatedNodes.clear();
        this.hiddenNodes.clear();

        const root = rootId || (this.model ? this.model.getRootId() : null);
        if (root) {
            // 2D datasets may need to call setAllVisibility on the model. This can have two possible reasons:
            //  a) they may have no instance tree, so that setting visibility on root (as below) is not possible.
            //  b) even if they have an instance tree, setting visibility on root node will only reach selectable ids.
            //     2D datasets may also contain unselectable objects with id <=0. In this case, the call below
            //     is needed to hide/show these as well when using isolate/show-all.
            if (this.model.is2d()) {
                this.model.setAllVisibility(visible);
            }
            // if we have an instance tree, we call setVisible on the root node
            this.setVisibilityOnNode(root, visible, false, true, trackIsolation);
        } else {
            this.model.setAllVisibility(visible);
            this.updateNodeVisibilityTracking(SYNTHETIC_ROOT_ID, visible, trackIsolation);
            this.viewerImpl.sceneUpdated(true, false);
        }
    }

    /**
     * @param {number} dbId
     * @returns {boolean}
     */
    isNodeVisible(dbId) {
        const instanceTree = this.getInstanceTree();
        if (instanceTree) {
            // get visibility from instance tree
            return !instanceTree.isNodeHidden(dbId);
        } else {
            // If there is no instance tree, we have ids, but no hierarchy.
            // Therefore, an id is only hidden if it appears in hiddenNodes or
            // if there are isolated nodes and dbId is not among these.
            if (this.hiddenNodes.has(dbId)) return false;
            // it's not in hidden, but neither there is any isolation => use a fallback dummy root to check for "all visible"
            if (!this.isolatedNodes.size) return !this.hiddenNodes.has(SYNTHETIC_ROOT_ID);
            return this.isolatedNodes.has(dbId);
        }
    }

    isolate(node) {
        const nodes = Array.isArray(node) ? node : [node];
        const root = nodes.find(node => this.#isRoot(node));
        if (!node) {
            // falsy node resets isolation
            this.setAllVisibility(true, false);
        } else if (root) {
            // explicit root forces isolation tracking
            this.setAllVisibility(true, true, root);
            // redundantly track the rest, as some intermediate nodes might be hidden,
            // but their children might still be visible (the "restore viewer state" case)
            const nodesWithoutRoot = nodes.filter(node => !this.#isRoot(node));
            for (const notRoot of nodesWithoutRoot) {
                this.updateNodeVisibilityTracking(notRoot, true, true);
            }
        } else {
            this.isolateMultiple(nodes);
        }
    }

    isolateNone() {
        this.setAllVisibility(true);
    }

    //Makes the children of a given node visible and
    //everything else not visible
    isolateMultiple(nodeList) {

        //If given nodelist is null or is an empty array or contains the whole tree
        if (!nodeList || nodeList.length == 0) {
            this.setAllVisibility(true);
        }
        else {
            this.setAllVisibility(false);

            const lastIndex = nodeList.length - 1;
            for (let i = 0; i < nodeList.length; i++) {
                this.setVisibilityOnNode(nodeList[i], true, i !== lastIndex, true, true);
            }
        }
    }

    //Makes the children of a given node visible and
    //everything else not visible
    hide(node, fireEvent = true, skipIsolated = false) {
        const nodes = Array.isArray(node) ? node : [node];

        if (!nodes.length) return;

        const lastIndex = nodes.length - 1;
        for (let i = 0; i < nodes.length; ++i) {
            this.setVisibilityOnNode(nodes[i], false, i !== lastIndex, true, skipIsolated);
        }
        if (fireEvent) this.viewerImpl.api.dispatchEvent({ type: et.HIDE_EVENT, nodeIdArray: nodes, model: this.model });
    }

    show(node) {
        const nodes = Array.isArray(node) ? node : [node];

        if (!nodes.length) return;

        const lastIndex = nodes.length - 1;
        for (let i = 0; i < nodes.length; ++i) {
            this.setVisibilityOnNode(nodes[i], true, i !== lastIndex);
        }

        this.viewerImpl.api.dispatchEvent({ type: et.SHOW_EVENT, nodeIdArray: nodes, model: this.model });
    }

    toggleVisibility(node) {
        this.isNodeVisible(node) ? this.hide(node) : this.show(node);
    }

    /**
     * @param {number} node
     * @param {boolean} visible
     * @param {boolean} [skipEventing=false] - optional flag to skip the resulting event, defaults to false
     * @param {boolean} [setRecursive=true] - optional flag to set the visibility recursively, defaults to true
     * @param {boolean} [trackIsolation=false] - optional flag to track isolation, defaults to false
     */
    setVisibilityOnNode(node, visible, skipEventing = false, setRecursive = true, trackIsolation = false) {
        const fragmentList = this.model.getFragmentList();
        const instanceTree = this.getInstanceTree();

        // keep track on if isolation is already active
        const isolated = !!this.isolatedNodes.size;

        if (instanceTree) {
            //Recursively process the tree under the root (recursion is inclusive of the root)
            instanceTree.enumNodeChildren(node, (dbId) => {

                const visibleLocked = instanceTree.isNodeVisibleLocked(dbId);

                // if hiding the isolated node while the isolation tracking's been requested (the "restore viewer state" case),
                // or hiding the locked node, skip it and stop recursion into its children
                if (!visible && trackIsolation && this.isolatedNodes.has(dbId)) {
                    return true;
                }

                instanceTree.setNodeHidden(dbId, !visible && !visibleLocked);

                if (this.model.is2d()) {
                    // If dbId is otg, we need to map back to original dbIds, because the
                    // vertex buffer contain original dbIds.
                    const origDbId = this.model.reverseMapDbIdFor2D(dbId);

                    fragmentList?.setObject2DGhosted(origDbId, !visible && !visibleLocked);
                } else {
                    instanceTree.enumNodeFragments(dbId, (fragId) => {
                        this.model.setVisibility(fragId, visible || visibleLocked);
                    }, false);
                }

                this.#untrackChild(node, dbId);
            }, setRecursive);
        } else {
            //No instance tree, assume fragId = dbId
            if (this.model.is2d()) {
                if (this.model.isLeaflet()) {
                    this.model.setVisibility(null, visible);
                } else {
                    fragmentList?.setObject2DGhosted(node, !visible);
                }
            } else {
                this.model.setVisibility(node, visible);
            }
        }

        this.updateNodeVisibilityTracking(node, visible, trackIsolation || isolated);

        this.viewerImpl.sceneUpdated(true, false, skipEventing);
    }

    /**
     * Updates a visiblity tracking of the node
     *
     * A few moments to consider:
     * - an isolation means "show this node and hide the rest"
     * - the `trackIsolation` flag must be set by the callers of this, as it can't be derived otherwise
     * - the "all hidden" state is tracked by hiding the root node
     * - since initial state is "all shown", `hide/show` is tracked by using `hiddenNodes`, but
     * - when the state is "all hidden", `show` becomes "show some nodes, while hiding everything else" i.e. again the isolation, so that:
     *   - `hide` on an isolated node becomes "remove isolation"
     *   - after the last isolated node is removed, it must again become "all hidden"
     *   - `hide` on a non-isolated node simply means adding the node to the hidden
     *
     * Overall, it's all about to be able to store/restore the visibility state of the model w/o loss of information.
     *
     * @param {number} node - the dbId of the node to track
     * @param {boolean} visible - the visibility flag, which has been applied to the node
     * @param {boolean} trackIsolation - the flag indicating if isolation is to be tracked
     */
    updateNodeVisibilityTracking(node, visible, trackIsolation) {
        // for the root it's easy: clear everything and track the root
        if (this.#isRoot(node)) {
            this.hiddenNodes.clear();
            this.isolatedNodes.clear();
            if (visible) {
                if (trackIsolation) this.isolatedNodes.add(node);
            } else {
                this.hiddenNodes.add(node);
            }
            return;
        }
        if (visible) {
            // simple case: was hidden, now shown
            if (this.hiddenNodes.has(node)) {
                this.hiddenNodes.delete(node);
                if (trackIsolation) {
                    this.isolatedNodes.add(node);
                }
            }
            else {
                // if everything was hidden (no isolation, and "all hidden" is marked with root),
                // start to track isolation even if it's not requested by the caller (see function description for details)
                if (this.isAllHidden()) {
                    this.hiddenNodes.clear();
                    this.isolatedNodes.add(node);
                } else {
                    if (trackIsolation) {
                        this.isolatedNodes.add(node);
                    }
                }
            }
        }
        else {
            // something is isolated
            if (this.isolatedNodes.size) {
                if (this.isolatedNodes.has(node)) {
                    this.isolatedNodes.delete(node);
                    // now, since isolation was active, but there is no isolated nodes anymore:
                    // - clear hidden nodes (no need to track them anymore)
                    // - track "all hidden" with root
                    if (!this.isolatedNodes.size) {
                        this.hiddenNodes.clear();
                        this.hiddenNodes.add(this.model.getRootId() || SYNTHETIC_ROOT_ID);
                    }
                } else {
                    // the non-isolated node to be hidden means we deal with a child of isolated node, simply track it
                    this.hiddenNodes.add(node);
                }
            } else {
                // nothing was isolated, track iff it's not "all hidden" already
                if (!this.isAllHidden()) this.hiddenNodes.add(node);
            }
        }
    }

    isAllHidden() {
        return !this.isolatedNodes.size && this.hiddenNodes.has(this.model.getRootId() || SYNTHETIC_ROOT_ID);
    }

    toggleVisibleLocked(node) {
        const instanceTree = this.getInstanceTree();
        if (!instanceTree) return;
        this.lockNodeVisible(node, !instanceTree.isNodeVisibleLocked(node));
    }

    lockNodeVisible(nodeList, locked) {

        const instanceTree = this.getInstanceTree();

        if (!instanceTree) return;

        // keep track on if isolation is already active
        const isolated = !!this.isolatedNodes.size;

        const lockOneNode = node => {
            //Recursively process the tree under the root (recursion is inclusive of the root)
            instanceTree.enumNodeChildren(node, (dbId) => {
                instanceTree.lockNodeVisible(dbId, locked);
                if (locked) {
                    instanceTree.setNodeHidden(dbId, false);

                    if (this.model.is2d()) {
                        // If dbId is otg, we need to map back to original dbIds, because the
                        // vertex buffer contain original dbIds.
                        const origDbId = this.model.reverseMapDbIdFor2D(dbId);

                        this.model.getFragmentList().setObject2DGhosted(origDbId, false);
                    } else {
                        instanceTree.enumNodeFragments(dbId, (fragId) => {
                            this.model.setVisibility(fragId, true);
                        }, false);
                    }

                    this.#untrackChild(node, dbId);
                }
            }, true);

            if (locked) {
                this.updateNodeVisibilityTracking(node, locked, isolated);
                this.viewerImpl.sceneUpdated(true);
            }
        };

        if (Array.isArray(nodeList)) {
            nodeList.forEach(node => lockOneNode(node));
        } else {
            lockOneNode(nodeList);
        }
    }

    isNodeVisibleLocked(node) {
        const instanceTree = this.getInstanceTree();
        return instanceTree?.isNodeVisibleLocked(node);
    }

    setNodeOff(node, isOff, nodeChildren, nodeFragments) {
        /**
         * This one is very special with regards to tracking of the isolated/hidden nodes.
         * It uses an independent flag - `NODE_FLAG_OFF` - and does not change the visibility flag, however,
         * the latter may still be changed by other methods, so that after the node is on again,
         * it appears visible or ghosted (or hidden, depending on the LMV settings) accordingly.
         * Also, the viewer state, as well as model browsers (LMV and ACC) do not support the off-nodes at the moment.
         * That been said, the off-nodes shall not be tracked using the existing `hidden/isolatedNodes` sets,
         * but a new set and its handling logic are to be added on demand, e.g., if somebody will report this as an issue.
         */

        const instanceTree = this.getInstanceTree();
        const fragmentList = this.model.getFragmentList();

        if (!instanceTree) {
            if (!fragmentList) return;

            if (this.model.is2d()) {
                fragmentList.setObject2DVisible(node, !isOff);
            } else {
                fragmentList.setFragOff(node, isOff);
            }
            this.viewerImpl.sceneUpdated(true);
            return;
        }

        if (nodeChildren && nodeFragments) {
            if (this.model.is2d()) {
                for (const dbId of nodeChildren) {
                    instanceTree.setNodeOff(dbId, isOff);
                    // If dbId is otg, we need to map back to original dbIds, because the
                    // vertex buffer contain original dbIds.
                    const origDbId = this.model.reverseMapDbIdFor2D(dbId);
                    fragmentList?.setObject2DVisible(origDbId, !isOff);
                }
            } else {
                for (const dbId of nodeChildren) {
                    instanceTree.setNodeOff(dbId, isOff);
                }
                for (const fragId of nodeFragments) {
                    fragmentList?.setFragOff(fragId, isOff);
                }
            }
        } else {
            //Recursively process the tree under the root (recursion is inclusive of the root)
            instanceTree.enumNodeChildren(node, (dbId) => {
                instanceTree.setNodeOff(dbId, isOff);

                if (this.model.is2d()) {
                    // If dbId is otg, we need to map back to original dbIds, because the
                    // vertex buffer contain original dbIds.
                    const origDbId = this.model.reverseMapDbIdFor2D(dbId);
                    fragmentList?.setObject2DVisible(origDbId, !isOff);
                } else {
                    instanceTree.enumNodeFragments(dbId, (fragId) => {
                        fragmentList?.setFragOff(fragId, isOff);
                    }, false);
                }
            }, true);
        }

        this.viewerImpl.sceneUpdated(true);
    }

    #isRoot(node) {
        const rootId = this.model.getRootId() || SYNTHETIC_ROOT_ID;
        if (typeof node === 'number') return node === rootId;
        if (typeof node == 'object') node?.dbId === rootId;
    }

    #untrackChild(parentId, childId) {
        if (parentId === childId) return;
        this.isolatedNodes.delete(childId);
        this.hiddenNodes.delete(childId);
    }
}

export class MultiModelVisibilityManager {

    /** @type {Viewer3DImpl} */
    viewerImpl;

    /** @type {Model[]} */
    models = [];

    /**
     * @param {Viewer3DImpl} viewerImpl
     */
    constructor(viewerImpl) {
        this.viewerImpl = viewerImpl;
    }

    /**
     * @param {Model} model
     */
    addModel(model) {
        if (this.models.indexOf(model) === -1) {
            model.visibilityManager = new VisibilityManager(this.viewerImpl, model);
            this.models.push(model);
        }
    }

    /**
     * @param {Model} model
     */
    removeModel(model) {
        const idx = this.models.indexOf(model);

        if (idx === -1) return;

        // clear visibility states (revert all ghosting)
        model.visibilityManager.setAllVisibility(true);

        model.visibilityManager = null;
        this.models.splice(idx, 1);
    }

    warn() {
        if (this.models.length > 1) {
            logger.warn("This selection call does not yet support multiple models.");
        }
    }

    /**
     * Get a list of all dbIds that are currently isolated, grouped by model.
     *
     * @returns {{ model: Model, ids: number[] }[]} Containing objects with `{ model: <instance>, ids: Number[] }`.
     * @private
     */
    getAggregateIsolatedNodes() {
        /** @type {{ model: Model, ids: number[] }[]} */
        const res = [];
        for (const model of this.models) {
            const ids = model.visibilityManager.getIsolatedNodes();
            if (ids.length) res.push({ model, ids });
        }
        return res;
    }

    /**
     * @param {Model} model
     * @returns {number[]}
     */
    getIsolatedNodes(model) {
        if (!model) {
            this.warn();
            model = this.models[0];
        }
        return model.visibilityManager.getIsolatedNodes();
    }

    /**
     * @returns {{ model: Model, ids: number[] }[]}
     */
    getAggregateHiddenNodes() {
        /** @type {{ model: Model, ids: number[] }[]} */
        const res = [];
        for (const model of this.models) {
            const ids = model.visibilityManager.getHiddenNodes();
            if (ids.length) res.push({ model, ids });
        }
        return res;
    }

    /**
     * @param {Model} model
     * @returns {number[]}
     */
    getHiddenNodes(model) {
        if (!model) {
            this.warn();
            model = this.models[0];
        }
        return model.visibilityManager.getHiddenNodes();
    }

    /**
     * @param {Model} model
     * @param {number} dbId
     * @returns {boolean}
     */
    isNodeVisible(model, dbId) {
        if (!model) {
            this.warn();
            model = this.models[0];
        }
        return model.visibilityManager.isNodeVisible(dbId);
    }

    /**
     * @param {number|number[]} node
     * @param {Model} model
     */
    isolate(node, model) {
        if (!model) {
            this.warn();
            model = this.models[0];
        }

        // unlike aggregateIsolate, calling isolate only touches the passed model
        this.aggregateIsolate([{ model, ids: node }], { hideLoadedModels: false });
    }

    /**
     * Isolate nodes (dbids) associated with a model.
     * @param {object[]} isolation - an array of isolation objects
     * @param {Model} isolation.model - Model
     * @param {number|number[]} isolation.ids - dbids to isolate
     * @param {object} [options] - custom options
     * @param {boolean} [options.hideLoadedModels=true] - if set to `false`, the visibility of other loaded models will not change (`true` by default)
     */
    aggregateIsolate(isolation, options) {
        if (!isolation || isolation.length === 0) { // all visible
            for (const model of this.models) {
                model.visibilityManager.setAllVisibility(true);
            }
        } else { // Something's isolated
            const hideOthers = options?.hideLoadedModels ?? true;

            for (const model of this.models) {

                const item = isolation.find(i => i.model == model);

                if (item) {
                    const ids = item.ids || item.selection;

                    model.visibilityManager.isolate(ids);
                } else if (hideOthers) {
                    model.visibilityManager.setAllVisibility(false);
                }
            }
        }
        this.#fireAggregateIsolationChangedEvent();
    }

    /**
     * Makes the children of a given node visible and everything else not visible
     *
     * @param {number} node
     * @param {Model} model
     */
    hide(node, model) {
        if (!model) {
            this.warn();
            model = this.models[0];
        }
        model.visibilityManager.hide(node);
    }

    /**
     * Hide the dbids associated in a model
     *
     * Fires the Autodesk.Viewing.AGGREGATE_HIDDEN_CHANGED_EVENT.
     *
     * @param {{ model: Model, ids: number[] }[]} nodes
     */
    aggregateHide(nodes) {
        if (!nodes || nodes.length === 0) return;
        for (const node of nodes) {
            const model = node.model;
            const ids = node.ids || node.selection;
            // Fire the av.HIDE_EVENT only if there is one entry.
            model.visibilityManager.hide(ids, this.models.length === 1);
        }

        const hidden = this.getAggregateHiddenNodes();
        this.viewerImpl.api.dispatchEvent({ type: et.AGGREGATE_HIDDEN_CHANGED_EVENT, hidden });
    }

    /**
     * Restore states of nodes (dbIds) associated with a model
     *
     * A state may have both isolated and hidden nodes.
     *
     * @param {object[]} isolateAndHide - the array of states
     * @param {Model} isolateAndHide.model - the model
     * @param {number[]} isolateAndHide.isolatedIds - the array of dbIds to isolate
     * @param {number[]} isolateAndHide.hiddenIds - the array of dbIds to hide
     */
    aggregateRestore(isolateAndHide) {
        if (!isolateAndHide || isolateAndHide.length === 0) {
            return;
        }
        for (const model of this.models) {
            const state = isolateAndHide.find(i => i.model === model);
            if (!state) continue;
            const { isolatedIds, hiddenIds } = state;
            model.visibilityManager.isolate(isolatedIds);
            // Fire the av.HIDE_EVENT only if there is one entry.
            model.visibilityManager.hide(hiddenIds, this.models.length === 1, true);
        }
        this.#fireAggregateIsolationChangedEvent();
        const hidden = this.getAggregateHiddenNodes();
        this.viewerImpl.api.dispatchEvent({ type: et.AGGREGATE_HIDDEN_CHANGED_EVENT, hidden });
    }

    /**
     * Inverse of `hide`
     *
     * @param {number} node
     * @param {Model} model
     */
    show(node, model) {
        if (!model) {
            this.warn();
            model = this.models[0];
        }
        model.visibilityManager.show(node);
    }

    /**
     * Toggle the visibility of the node given
     *
     * @param {number} node
     * @param {Model} model
     */
    toggleVisibility(node, model) {
        if (!model) {
            this.warn();
            model = this.models[0];
        }
        model.visibilityManager.toggleVisibility(node);
    }

    /**
     * @param {number} node
     * @param {boolean} visible
     * @param {Model} model
     * @param {boolean} [skipEventing=false] - optional flag to skip the resulting event, defaults to false
     * @param {boolean} [setRecursive=true] - optional flag to set the visibility recursively, defaults to true
     */
    setVisibilityOnNode(node, visible, model, skipEventing = false, setRecursive = true) {
        if (!model) {
            this.warn();
            model = this.models[0];
        }
        model.visibilityManager.setVisibilityOnNode(node, visible, skipEventing, setRecursive);
    }

    /**
     * @param {number} node
     * @param {Model} model
     */
    toggleVisibleLocked(node, model) {
        if (!model) {
            this.warn();
            model = this.models[0];
        }
        model.visibilityManager.toggleVisibleLocked(node);
    }

    /**
     * @param {number} node
     * @param {boolean} locked
     * @param {Model} model
     */
    lockNodeVisible(node, locked, model) {
        if (!model) {
            this.warn();
            model = this.models[0];
        }
        model.visibilityManager.lockNodeVisible(node, locked);
    }

    /**
     * @param {number} node
     * @param {Model} model
     * @returns {boolean}
     */
    isNodeVisibleLocked(node, model) {
        if (!model) {
            this.warn();
            model = this.models[0];
        }
        return model.visibilityManager.isNodeVisibleLocked(node, model);
    }

    /**
     * @param {number} node
     * @param {boolean} isOff
     * @param {Model} model
     * @param {number[]} nodeChildren
     * @param {number[]} nodeFragments
     */
    setNodeOff(node, isOff, model, nodeChildren, nodeFragments) {
        if (!model) {
            this.warn();
            model = this.models[0];
        }
        model.visibilityManager.setNodeOff(node, isOff, nodeChildren, nodeFragments);
    }

    /**
     * @private
     */
    #fireAggregateIsolationChangedEvent() {

        const isolation = this.getAggregateIsolatedNodes();

        // Legacy event
        if (this.models.length === 1) {
            this.viewerImpl.api.dispatchEvent({
                type: et.ISOLATE_EVENT,
                nodeIdArray: isolation.length ? isolation[0].ids : [],
                model: this.models[0]
            });
        }

        // Always fire
        this.viewerImpl.api.dispatchEvent({ type: et.AGGREGATE_ISOLATION_CHANGED_EVENT, isolation });
    };
}
