import { OBJECT_STRIDE, ObjectUniforms, UniformOffsets } from './ObjectUniforms.js';
import { USE_OUT_OF_CORE_TILE_MANAGER, USE_DYNAMIC_UNIFORM_ALLOCATION } from "../../../globals";
import { BumpAllocator } from '../../BumpAllocator.js';
import { RenderBatch } from '../../../scene/RenderBatch.js';

// Estimated limit for maximum size of a batch. Used for conservative esimtation of required buffer sizes.
//
// Currently, using OutOfCoreTileManager can result in drawing objects with many
// instances. These don't fit in the current 512 MAX_BATCH limit and this results
// in an infinite loop in RenderBatchShim.forEachWGPU().
// HACKY FIX: Increase this limit to be large enough for all models I've tried.
// A proper fix might be a mechanism to resume drawing on the same object, but
// starting at a different instance to avoid this infinite loop.
export const MAX_BATCH = () => USE_OUT_OF_CORE_TILE_MANAGER ? 30000 : 512;

// The GPUSide UniformBuffers can be interpreted as arrays of "items", where
// each item stores the uniform data for a single object.
const getMaxItemCount = (gpuBuffer) => {
    return gpuBuffer.size / OBJECT_STRIDE;
};

/**
 * Attached to RenderBatches to find the corresponding UniformBuffer range where the
 * ObjectUniforms are stored. Can be seen as a span [startIndex, startIndex + length) in the GPU side array of entries.
 * @typedef {Object} ObjectUniformBufferRange
 * @property {number} bufferIndex  // index of the GPU buffer where the range is stored. Note that ranges cannot cross buffer boundaries.
 * @property {number} startIndex   // start index of the range in the GPU buffer
 * @property {number} length       // number of items in the range
 */

/**
 * Manages one or more GPU-side buffers to store object uniforms. Each buffer can be seen as a GPU-side array of items,
 * whereby each item stores the uniform data for a single object.
 *
 * Todo: Currently, it's a simple bump allocator scheme of uniforms. We will need it to be more flexibile, probably by
 *       using a smarter unified memory management strategy that is used for other buffers as well.
 */
export class ObjectUniformBuffer {

    /** @type {GPUDevice} */
    #device;
    /** @type {ObjectUniforms} */
    #uniforms;

    /** @type {BumpAllocator} */
    #allocator; // Handles the core GPU memory management

    /** @type {Map<number, BufferEntry} */
    #indexToBufferEntry = new Map(); // Find BufferEntry in this.#allocator for a given id

    // One or more GPUBuffers to hold batched object uniforms
    /** @type {GPUBuffer[]} */
    #gpuBuffers = [];

    // Next free item in the GPU-side buffer
    #nextGPUWriteIndex = 0;
    #currentBufferIndex = 0; // index into #gpuBuffers - the buffer that we are currently uploading to

    // Maximum number of objects that fit into a single GPUBuffer. Decided by GPU limits.
    #maxModelBufferByteSize; // size in bytes
    #maxFragsPerBuffer;      // how many objects can be stored

    // MaterialUniformPool - manages shared material uniforms referenced by object uniforms
    #materials = null;

    #isFixedSize = false;

    /** @type {Map<RenderBatch, ObjectUniformBufferRange>} */
    #ranges = new Map();

    // @param {GPUDevice} device
    // @param {MaterialUniformStorage} materials - Separate storage of material uniforms that are shared by multiple objects.
    //                                             Entries of this storage are referenced by the object uniforms.
    // @param {number} [initialSize=0]   - in bytes. If >0, we allocate a buffer immediately. If 0, no buffers are allocated yet.
    // @param {boolean} [fixedSize=true] - If true, we just allocate a fixed buffer size. Otherwise, we allow dynamic alloc and free.
    constructor(
        device,
        materials,
        initialSize = 0,
        fixedSize = false
    ) {
        this.#device      = device;
        this.#materials   = materials;
        this.#isFixedSize = fixedSize;

        // We need a CPU-side buffer to collect/store object uniforms before uploading them to the GPU.
        // This buffer is smaller than the full GPU-side UniformBuffer.
        // The CPU side buffer size is chosen to fit MaxItemsPerUpload objects. This values defines the maximum
        // possible number of objects for which we can add uniform values before flusing the data to GPU.
        const MaxItemsPerUpload = MAX_BATCH();
        this.#uniforms = new ObjectUniforms(MaxItemsPerUpload);

        // We use the old code only for fixed size buffer (for per-frame uniforms)
        if (fixedSize || !USE_DYNAMIC_UNIFORM_ALLOCATION) {
            // Determine buffer size based on device limits
            const bufferLimit = this.#device.limits.maxStorageBufferBindingSize;
            this.#maxFragsPerBuffer      = Math.floor(bufferLimit / OBJECT_STRIDE);
            this.#maxModelBufferByteSize = this.#maxFragsPerBuffer * OBJECT_STRIDE;

            // optional: alloc initial buffer
            if (initialSize > 0) {
                this.allocNewGPUBuffer(initialSize);
            }
        } else {
            // Use a bump allocator for dynamic buffer allocation
            this.#allocator = new BumpAllocator(device);
        }
    }

    dtor() {
        this.clear(); // delete GPU buffers
        this.#device     = null;
        this.#materials  = null;
    }

    /**
     * @returns {number} Summed byteSize of all ObjectUniforms on GPU
     */
    byteSize() {
        if (USE_DYNAMIC_UNIFORM_ALLOCATION && !this.#isFixedSize) {
            return this.#allocator.computeUsedBytes().ubBytes;
        }
        return this.#gpuBuffers.reduce((acc, buffer) => acc + buffer.size, 0);
    }

    // Clear all uniform buffers.
    //  @param {bool} [deleteBuffers=true] - If false, we keep the actual GPUBuffers for reuse and only consider them as unused.
    clear(deleteBuffers = true) {
        if (deleteBuffers) {
            for (const buffer of this.#gpuBuffers) {
                buffer?.destroy();
            }
            this.#gpuBuffers = [];
        }

        // Reset cursore where to allocate next
        this.#nextGPUWriteIndex = 0;
        this.#currentBufferIndex = 0;
    }

    // Hardware limit for the maximum possible number of uniforms that can be stored in a single GPUBuffer.
    get maxFragsPerBuffer() {
        return this.#maxFragsPerBuffer;
    }

    // Hardware limit for the maximum byteSize of a single GPUBuffer.
    get maxModelBufferByteSize() {
        return this.#maxModelBufferByteSize;
    }

    // Allocate a new GPU buffer with the given byteSize and uses it for subsequent range allocations.
    allocNewGPUBuffer(byteSize) {

        const newBuffer = this.#device.createBuffer({
            size: byteSize,
            // Usage type "storage" allows much larger buffers than "uniform"
            usage: GPUBufferUsage.STORAGE | GPUBufferUsage.COPY_DST,
        });

        this.#gpuBuffers.push(newBuffer);

        // start allocating new stuff at index 0 in the new buffer
        this.#currentBufferIndex = this.#gpuBuffers.length - 1;
        this.#nextGPUWriteIndex = 0;
    }

    /**
     * Allocates one or more GPU Buffers to store batched uniforms for a whole model.
     * Note that the size is just estimated and not guaranteed to be sufficient: Since RenderBatches
     * cannot cross buffer boundaries, there can be some unused space.
     * If more buffers are needed, they are allocated on-demand later. (see allocUniformBufferRange)
     * @param {number} fragCount - number of fragments for which we need to store UniformBuffer entries.
     */
    preallocateModelBuffers(fragCount) {
        // Determine required total byteSize for storing uniforms for all fragments of the model
        const modelSize = fragCount * OBJECT_STRIDE;

        // Based on number of fragments in the model, determine how many buffers we need
        let numBuffers = Math.ceil(modelSize / this.#maxModelBufferByteSize);

        // Consider some potential waste due to the constraint that we cannot split a batch across buffers.
        // Note that this relies on MAX_BATCH as the upper limit for fragments per batch. In rare cases, batches can be
        // much larger. Additional buffers are allocated in the uniform updater class in this case.
        const potentialWaste = (numBuffers - 1) * (MAX_BATCH() - 1) * OBJECT_STRIDE;
        if (numBuffers * this.#maxModelBufferByteSize < (modelSize + potentialWaste)) {
            numBuffers += 1;
        }

        // Determine last buffer size
        let lastBufferSize = (((modelSize + potentialWaste) / this.#maxModelBufferByteSize) % 1) * this.#maxModelBufferByteSize;
        if (lastBufferSize === 0) {
            lastBufferSize = this.#maxModelBufferByteSize;
        } else {
            //round up to next multiple of 4
            lastBufferSize = (lastBufferSize + 3) & 0xfffffffc;
        }

        // Allocate the GPUBuffers
        for (let i=0; i<numBuffers - 1; i++) {
            this.allocNewGPUBuffer(this.#maxModelBufferByteSize);
        }
        this.allocNewGPUBuffer(lastBufferSize);

        // We just reserved the buffers, but didn't write any data yet.
        // So, start writing with buffer 0.
        this.#currentBufferIndex = 0;
    }

    /**
     * Allocate a range in a GPU-side buffer to store uniforms for a range of fragments.
     *
     * Note:
     *  - The internally used Bumpallocator does not handle out-of-memory cases so far. We may consider a mechanism to
     *    fallback to dynamic uniforms. Currently, we just rely on OutOfCoreTileManager to make sure that we don't run out of memory.
     * @param {RenderBatch} batch - The render batch for which we need to store UniformBuffer entries.
     * @returns {ObjectUniformBufferRange|null}
     */
    allocUniformBufferRange(batch) {
        const rangeLength = batch.count;

        // Currently, a range with > 128MB uniforms would fail to allocate. However, the BVH construction currently restricts
        // to 65K fragments per node anyway.
        const maxRangeLength = Math.floor(BumpAllocator.maxUniformBufferSize() / OBJECT_STRIDE);
        if (rangeLength > maxRangeLength) {
            console.error(`Requested range length ${rangeLength} exceeds maximum buffer size ${BumpAllocator.maxUniformBufferSize()}`);
            return null;
        }

        if (USE_DYNAMIC_UNIFORM_ALLOCATION) {
            const byteSize = OBJECT_STRIDE * rangeLength;
            const alloc = this.#allocator.uAlloc(byteSize);

            // Make sure that we can resolve the buffer id later
            const bufferIndex = alloc.bufferEntry.id;
            this.#indexToBufferEntry.set(bufferIndex, alloc.bufferEntry);

            // Reserve range in current GPU Buffer and return it.
            const range = {
                bufferIndex,
                startIndex: alloc.offset / OBJECT_STRIDE,
                rangeLength,
            };
            this.#ranges.set(batch, range);
            return range;
        }

        // TODO: Rest is legacy code and will be removed soon.
        // A range must always fit into a single GPUBuffer. Otherwise, the range should be
        // split outside and use separate bind groups.
        if (rangeLength > this.#maxFragsPerBuffer) {
            console.error('Range must fit into a single GPU buffer');
            return null;
        }

        // Check how many items we can fit into the current GPU buffer
        let maxItems = this.#remainingCapacityGPU();

        // If the range doesn't fit into remaining space of the current GPU buffer,
        // check if we find another one.
        while (maxItems < rangeLength) {
            // Note: In theory, this could cause skipping several buffers and leaving them unused.
            // However,
            //  - Currently this is not a problem, because we allocate the modelBuffers in proper sizes.
            //    The worst case that can happen is the edge case of having a single buffer skipped, if the
            //    last RenderBatch is too big to fit in.
            //  - In the future, we will need to make the alloc/free schema here more flexible anyway (instead of just forward-filling).

            // Try if more GPUBuffers are available.
            const success = this.#startNextBuffer();
            if (!success) {
                return null;
            }

            // Update maxItems, because we switched to a new buffer now.
            maxItems = this.#remainingCapacityGPU();
        }

        // Reserve range in current GPU Buffer and return it.
        const range = {
            bufferIndex: this.#currentBufferIndex,
            startIndex:  this.#nextGPUWriteIndex,
            rangeLength: rangeLength
        };
        this.#ranges.set(batch, range);

        // Update index where to write the next one
        this.#nextGPUWriteIndex += rangeLength;

        return range;
    }

    /**
     * Free any uniform buffer memory associated with this RenderBatch.
     * @param {RenderBatch} batch
     */
    freeUniformBufferRange(batch) {
        const range = this.getRange(batch);
        if (!range) {
            return;
        }
        const entry = this.#indexToBufferEntry.get(range.bufferIndex);
        if (!entry) {
            return;
        }
        const byteOffset = range.startIndex * OBJECT_STRIDE;
        const byteSize   = range.rangeLength * OBJECT_STRIDE;
        this.#allocator.free(entry, byteOffset, byteSize);
        this.clearRange(batch);
    }

    /**
     * Get the range associated with this RenderBatch if one exists.
     * @param {RenderBatch} batch
     * @returns {ObjectUniformBufferRange|undefined}
     */
    getRange(batch) {
        return this.#ranges.get(batch);
    }

    /**
     * Clear the mapping from this batch to an ObjectUniformBufferRange.
     * @param {RenderBatch} batch
     */
    clearRange(batch) {
        this.#ranges.delete(batch);
    }

    // Uploads the uniforms for a list of fragments to a range in the GPU-side buffer.
    // Each item in the UniformBuffer range corresponds to a fragmentId in the given fragIds array.
    updateRange(
        fragList,      // {FragmentList}
        fragIds,       // {Int32Array} of fragment ids
        srcRangeStart, // {number} range within fragIds array for which we want to upload the uniforms
        rangeLength,   // {number} number of fragments to update. Must match with the length of the available GPUBuffer range.
        dstRangeStart, // {number} start index of the range within the GPU-side uniform buffer where we upload to
        dstBufferIndex // {number} index of the GPU buffer where the range is stored
    ) {
        // get uniform buffer to write to
        const gpuBuffer = this.getGPUBuffer(dstBufferIndex);

        // Large ranges might exceed the CPU-side buffer. If so, we may have to
        // split into multiple upload steps.
        let itemsAdded = 0;
        while (itemsAdded < rangeLength) {

            // Check how many items we can fit into CPU buffer. This limits the number of items that we can upload in one go.
            const maxItems = this.#uniforms.length();

            // Determine how many items we upload in this loop cycle
            const itemsLeft  = rangeLength - itemsAdded;
            const itemsToAdd = Math.min(itemsLeft, maxItems);

            // Write uniform data to the CPU buffer
            const srcOffset = srcRangeStart + itemsAdded;
            const dstOffset = 0; // Note that in the CPU buffer, we start at 0. Offsetting by dstRangeStart and added items is only relevant for the GPU upload.
            this.#writeUniforms(fragList, fragIds, srcOffset, itemsToAdd, dstOffset);

            // Determine start index of the GPU-buffer range that we write to.
            // I.e., the previously saved index 0 in the CPU-side buffer will be written to the GPU buffer at index gpuWriteIndex.
            const gpuItemIndex = dstRangeStart + itemsAdded;

            // upload CPU uniform data to GPU buffer
            this.#uniforms.upload(this.#device, gpuBuffer, itemsToAdd, gpuItemIndex);

            itemsAdded += itemsToAdd;
        }
    }

    /**
     * @number {number} index
     * @number {boolean} [errorIfMissing=true] - If true, we expect the buffer to exist and log an error otherwise.
     * @returns {GPUBuffer|null}
     */
    getGPUBuffer(index, errorIfMissing = true) {

        let gpuBuffer;
        if (USE_DYNAMIC_UNIFORM_ALLOCATION && !this.#isFixedSize) {
            // Get the GPUBuffer for the given index
            const entry = this.#indexToBufferEntry.get(index);
            gpuBuffer = entry?.buffer;
        } else {
            gpuBuffer = this.#gpuBuffers[index];
        }

        // Report optional error
        if (errorIfMissing && !gpuBuffer) {
            console.error(`Unexpcected GPUBuffer index: ${index}. No GPUBuffer found.`);
        }

        return gpuBuffer || null;
    }

    /**
     * Set objectUniforms in cpu-side buffer for a single fragment.
     * @param {FragmentList} fragList
     * @param {number} fragId
     * @param {number} index - array index in the CPU side buffer where we collect data for uploading.
     **/
    setOneFragmentCPU(fragList, fragId, index) {
        // If this fragment uses a material that is not on GPU yet, acquire memory for the material too.
        // This is necessary to obtain a valid materialReference for the fragment.
        const material = fragList.getMaterial(fragId);
        const materialRef = this.#materials.acquireMaterial(material);

        // Write object uniforms to position 'index' in the CPU buffer
        this.#uniforms.setFromFragment(index, fragList, fragId, materialRef);
    }

    /**
     * Set and upload uniforms for a single fragment immediately.
     * @param {FragmentList} fragList
     * @param {number} fragId
     * @param {number} gpuBufferIndex - index of the GPUBuffer that stores the uniform for the fragment to change.
     * @param {number} gpuItemIndex - Array index of the item to be changed within the GPU buffer
     **/
    setOneFragment(fragList, fragId, gpuBufferIndex, gpuItemIndex) {

        // Write object uniforms to position 0 in the CPU buffer
        this.setOneFragmentCPU(fragList, fragId, 0);

        // Upload uniform at index 0 to the GPU buffer
        const gpuBuffer = this.getGPUBuffer(gpuBufferIndex);
        gpuBuffer && this.#uniforms.upload(this.#device, gpuBuffer, 1, gpuItemIndex, 0);
    }

    /**
     * Set uniforms for one object, but only in the CPU side buffer without uploading.
     * Upload is usually done afterwards for a whole batch at once, even for per-frame uniforms.
     *  @param {THREE.Mesh} mesh
     *  @param {number}     objectIndex - array index in the CPU side buffer where we collect data for uploading.
     */
    setOneObjectCPU(mesh, objectIndex) {
        // Make sure that we allocated memory for the uniforms
        // of this material and obtain the materialRef that we
        // need for the objectUniforms to refer to this material.
        const materialRef = this.#materials.acquireMaterial(mesh.material)

        // Write object uniform data
        this.#uniforms.setFromObject(mesh, objectIndex, materialRef);
    }

    /**
     * Sets object uniforms in the CPU-side buffer. Similar to setOneObjectCPU, but filling up uniforms for several subsequent
     * objects at once and  data from an instanced mesh.
     *  @param {THREE.Mesh} instancedMesh - must have geometry with geom.numInstance value and prepared instance buffer.
     *  @param {number} itemOffset - index in the CPU side buffer where we start writing.
     */
    setObjectDataFromInstanceBuffer(instancedMesh, itemOffset) {

        // Make sure the material itself is on GPU and get the reference to it
        const material = instancedMesh.material;
        this.#materials.updateMaterial(material);
        const materialRef = this.#materials.getMaterialRef(material);

        const geometry = instancedMesh.geometry;
        this.#uniforms.setObjectDataFromInstanceBuffer(geometry, itemOffset, materialRef);
    }

    /**
     * Calling this is only needed if you used setOneObjectCPU. Uploads the CPU-side data to the GPU buffer.
     * @param {number} itemCount - Number of items to upload. We upload the range [0, itemCount) from CPU buffer.
     */
    writeToQueue(itemCount, gpuBufferIndex = 0) {
        const gpuBuffer = this.getGPUBuffer(gpuBufferIndex);
        gpuBuffer && this.#uniforms.upload(this.#device, gpuBuffer, itemCount, 0, 0, true);
    }

    // Replace material for a single fragment.
    setMaterial(
        material,       // {THREE.Material}
        gpuBufferIndex, // {number} Index of the GPUBuffer that stores the uniform for the fragment to change.
        gpuItemIndex    // Array index of the item to be changed within the GPU buffer
    ) {
        // Make sure the material itself is on GPU and get the reference to it
        this.#materials.updateMaterial(material);
        const materialRef = this.#materials.getMaterialRef(material);

        // Write the reference to the material into the GPU UniformBuffer
        const gpuBuffer = this.getGPUBuffer(gpuBufferIndex);
        gpuBuffer && this.#uniforms.uploadIntValue(this.#device, gpuBuffer, gpuItemIndex, UniformOffsets.materialReference, materialRef);
    }

    // Set transform for a single ObjectUniformBuffer item.
    updateTransform(
        fragList,       // {FragmentList}
        fragId,         // {number}
        gpuBufferIndex, // {number} Index of the GPUBuffer that stores the uniform for the fragment to change.
        gpuItemIndex    // Array index of the item to be changed within the GPU buffer
     ) {
        const gpuBuffer = this.getGPUBuffer(gpuBufferIndex);
        gpuBuffer && this.#uniforms.uploadTransform(this.#device, gpuBuffer, gpuItemIndex, fragList, fragId);
     }

    /**
     * Sets and uploads the theming color uniform for the object at the specified index.
     *  @param {THREE.Vector4} color The theming color vector of the fragment.
     */
    setThemingColor(
        color,          // THREE.Vector4
        gpuBufferIndex, // {number} Index of the GPUBuffer that stores the uniform for the fragment to change.
        gpuItemIndex    // Array index of the item to be changed within the GPU buffer
    ) {
        const gpuBuffer = this.getGPUBuffer(gpuBufferIndex);
        gpuBuffer && this.#uniforms.uploadThemingColor(this.#device, gpuBuffer, gpuItemIndex, color);
    }

    /**
     * Direct access to ObjectUniforms
     * @returns {ObjectUniforms}
     */
    getUniformBuilder() {
        return this.#uniforms;
    }

    //
    // Private methods
    //

    // Computes how many more items we can add to the current GPUBuffer
    // before we need to allocate a new one.
    #remainingCapacityGPU() {
        const buffer   = this.getGPUBuffer(this.#currentBufferIndex);
        const maxItems = getMaxItemCount(buffer);
        return maxItems - this.#nextGPUWriteIndex;
    }

    // Write a range of fragment uniform data to CPU-buffer (for later upload) while assuming...
    //  1. The range fits into the remaining capacity of the CPU buffer of this.#uniformBuilder
    //  2. The range fits into the remaining capacity of the current GPU buffer
    // Note that the CPU-side transfer buffer (1.) is usually smaller than the whole GPU buffer (2.)
    #writeUniforms(
        fragList,   // FragmentList
        fragIds,    // Int32Array of fragment ids
        rangeStart, // start index of a range in fragIds
        rangeLength,
        dstOffset = 0 // start index where to write into the CPU buffer
    ) {
        for (let i = 0; i < rangeLength; i++) {
            // get fragId and material
            const srcIndex = rangeStart + i;
            const fragId   = fragIds[srcIndex];
            const material = fragList.getMaterial(fragId);

            // Material may initially be undefined. In this case, materialRef will be unset at first.
            // Note that materialRef  0 has no special meaning but just means "whatever, it doesn't matter" in this context.
            // However, this is not a problem, because the fragment will not be rendered until the materialRef is set.
            let materialRef = 0;
            if (material) {
                // This also makes sure that materialRef exists if this is the first time we see this material.
                materialRef = this.#materials.acquireMaterial(material);
            }

            // Write object uniforms to position 'writeIndex' in the CPU buffer
            const writeIndex = dstOffset + i;
            this.#uniforms.setFromFragment(writeIndex, fragList, fragId, materialRef);
        }
    }

    // Switch to next available GPU buffer for further range allocations.
    // If no more buffers are available, it returns false.
    #startNextBuffer() {
        const nextBuffer = this.getGPUBuffer(this.#currentBufferIndex + 1, false);
        if (nextBuffer) {
            this.#currentBufferIndex += 1;
            this.#nextGPUWriteIndex = 0;
            return true;
        }
        return false;
    }
}
