import { $wgsl } from "../../wgsl-preprocessor/wgsl-preprocessor";
import { UniformBuffer } from "../UniformBuffer";
import { LineMaterial } from "../../render/LineMaterial";
import { Renderer} from "../Renderer";
import { initTexture } from "../Texture";
import { MipmapPipeline } from "../main/MipmapPipeline";
import lineMaterialUniforms from "./line_material_uniforms.wgsl";

const Offsets = {
    selectionColor: 0,
    hasTexture: 4,
    hasLayers: 5,
    // Use these offsets next if your data fits.
    padding1: 6,
    padding2: 7,
    // Update size when adding values:
    SIZE_IN_FLOATS: 8,
}

const Bindings =  {
    lineMat: 0,
    rasterTexture: 1,
    rasterSampler: 2,
    selectionTexture: 3,
    layerTexture: 4,
    layerSampler: 5,
};

export class LineMaterialManager {
    /**
     * @param {number} group
     */
    static getUniformsDeclaration(group) {
        return $wgsl(lineMaterialUniforms, { group });
    }

    /** @type {GPUDevice} */
    #device;
    /** @type {TextureInfo} */
    #defaultTexture;
    /** @type {MipmapPipeline} */
    #mipmapPipeline;
    /** @type {GPUSampler} */
    #layerSampler;
    /** @type {GPUBindGroupLayout} */
    #layout;

    #boundUpdate;
    #boundDispose;

    // Only used as a placeholder in case uniforms.selectionColor is not specified.
    #defaultSelectionColor = new THREE.Vector4(0, 0, 1, 1);

    /**
     * @param {Renderer} renderer
     */
    constructor(renderer) {
        this.#device = renderer.getDevice();
        this.#defaultTexture = renderer.getPlaceholderTexture();
        this.#mipmapPipeline = new MipmapPipeline(this.#device);
        this.#layerSampler = this.#device.createSampler({
            label: '2d layer sampler',
            addressModeU: 'clamp-to-edge',
            addressModeV: 'clamp-to-edge',
            magFilter: 'nearest',
            minFilter: 'nearest',
        });

        this.#layout = this.#device.createBindGroupLayout({
            entries: [
                {
                    binding: Bindings.lineMat,
                    visibility: GPUShaderStage.FRAGMENT | GPUShaderStage.VERTEX,
                    buffer: {}
                },
                {
                    binding: Bindings.rasterTexture,
                    visibility: GPUShaderStage.FRAGMENT,
                    texture: {
                        sampleType: 'float',
                    }
                },
                {
                    binding: Bindings.rasterSampler,
                    visibility: GPUShaderStage.FRAGMENT,
                    sampler: {}
                },
                {
                    binding: Bindings.selectionTexture,
                    visibility: GPUShaderStage.FRAGMENT | GPUShaderStage.VERTEX,
                    texture: {
                        sampleType: 'float',
                    }
                },
                {
                    binding: Bindings.layerTexture,
                    visibility: GPUShaderStage.VERTEX,
                    texture: {
                        sampleType: 'float',
                    }
                },
                {
                    binding: Bindings.layerSampler,
                    visibility: GPUShaderStage.VERTEX,
                    sampler: {}
                },
            ]
        });

        this.#boundUpdate = this.handleUpdate.bind(this);
        this.#boundDispose = this.handleDispose.bind(this);
    }

    /**
     * @returns {GPUBindGroupLayout}
     */
    getLayout() {
        return this.#layout;
    }

    /**
     * @param {LineMaterial} material
     */
    initMaterial(material) {
        if (!material.__buffer) {
            material.__buffer = new UniformBuffer(
                this.#device, Offsets.SIZE_IN_FLOATS, /*visibleInFragment=*/true, /*visibleInVertex=*/false);
        }

        let rasterView;
        let rasterSampler;

        const rasterTexture = material.uniforms["tRaster"]?.value;
        if (rasterTexture) {
            initTexture(this.#device, this.#mipmapPipeline, rasterTexture);
        }
        if (rasterTexture && rasterTexture.__gpuTexture) {
            // hasTexture = true
            material.__buffer.setInt(Offsets.hasTexture, 1);
            rasterView = rasterTexture.__gpuTexture.createView();
            rasterSampler = rasterTexture.__gpuSampler;
        } else {
            // hasTexture = false
            material.__buffer.setInt(Offsets.hasTexture, 0);
            rasterView = this.#defaultTexture.view;
            rasterSampler = this.#defaultTexture.sampler;
        }

        const selectionColor = material.uniforms.selectionColor?.value || this.#defaultSelectionColor;
        material.__buffer.setVector4(Offsets.selectionColor, selectionColor);

        let selectionTextureView = null;
        const selectionTex = material.uniforms["tSelectionTexture"]?.value;
        if (selectionTex) {
            // Actually, selectionTexture is usually never mipmapped and mipmapPipeline will not be needed.
            // Just passing here because we have it anyway and don't need to make implicit assumptions.
            initTexture(this.#device, this.#mipmapPipeline, selectionTex);
        }
        if (selectionTex && selectionTex.__gpuTexture) {
            selectionTextureView = selectionTex.__gpuTexture.createView();
        } else {
            selectionTextureView = this.#defaultTexture.view;
        }

        const layerTexture = material.uniforms['tLayerMask']?.value;
        if (layerTexture) {
            initTexture(this.#device, this.#mipmapPipeline, layerTexture);
        }
        let layerView;
        if (layerTexture && layerTexture.__gpuTexture) {
            material.__buffer.setInt(Offsets.hasLayers, 1);
            layerView = layerTexture.__gpuTexture.createView();
        } else {
            material.__buffer.setInt(Offsets.hasLayers, 0);
            layerView = this.#defaultTexture.view;
        }

        material.__buffer.upload();
        material.__gpuBindGroup = this.#createBindGroup(
            material.__buffer.getBuffer(), rasterView, rasterSampler,
            selectionTextureView, layerView);

        material.needsUpdate = false;

        if (!material.hasEventListener('dispose', this.#boundDispose)) {
            material.addEventListener('dispose', this.#boundDispose);
        }

        if (!material.hasEventListener('update', this.#boundUpdate)) {
            material.addEventListener('update', this.#boundUpdate);
        }
    }

    updateTextures(material) {
        const rasterTexture = material.uniforms["tRaster"]?.value;
        if (rasterTexture && rasterTexture.needsUpdate) {
            initTexture(this.#device, this.#mipmapPipeline, rasterTexture);
        }

        const selectionTex = material.uniforms["tSelectionTexture"]?.value;
        if (selectionTex && selectionTex.needsUpdate) {
            initTexture(this.#device, this.#mipmapPipeline, selectionTex);
        }

        const layerTexture = material.uniforms['tLayerMask']?.value;
        if (layerTexture && layerTexture.needsUpdate) {
            initTexture(this.#device, this.#mipmapPipeline, layerTexture);
        }
    }

    /**
     * @param {Object} event
     * @param {LineMaterial} event.target
     */
    handleUpdate(event) {
        this.initMaterial(event.target);
    }

    /**
     * @param {Object} event
     * @param {LineMaterial} event.target
     */
   handleDispose(event) {
       const material = event.target;
       material.removeEventListener('dispose', this.#boundDispose);

        if (material.__buffer) {
            material.__buffer.getBuffer()?.destroy();
            material.__buffer = null;
        }

        const texture = material.uniforms["tRaster"]?.value;
        if (texture) {
            texture.dispose();
        }
    }

    /**
     * @param {GPUBuffer} buffer
     * @param {GPUTextureView} rasterView
     * @param {GPUSampler} rasterSampler
     * @param {GPUTextureView} selectionView
     * @param {GPUTextureView} layerView
     * @returns {GPUBindGroup}
     */
    #createBindGroup(buffer, rasterView, rasterSampler, selectionView, layerView) {
        return this.#device.createBindGroup({
            layout: this.#layout,
            entries: [
                {
                    binding: Bindings.lineMat,
                    resource: {
                        buffer,
                    }
                },
                {
                    binding: Bindings.rasterTexture,
                    resource: rasterView,
                },
                {
                    binding: Bindings.rasterSampler,
                    resource: rasterSampler,
                },
                {
                    binding: Bindings.selectionTexture,
                    resource: selectionView,
                },
                {
                    binding: Bindings.layerTexture,
                    resource: layerView,
                },
                {
                    binding: Bindings.layerSampler,
                    resource: this.#layerSampler,
                },
            ]
        });
    }
}
